import Tree from "@widgetjs/tree";

//notificaciones con toastr
import * as Toastr from "toastr";

//mapas
import L from "leaflet";
require("/node_modules/leaflet/dist/leaflet.css");

// Importa Leaflet Routing Machine y sus estilos
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine";

//notificaciones con PNotify, pero hay que importar directamente en el fichero
import PNotify from "/node_modules/pnotify/dist/es/PNotify.js";
import { add } from "@hotwired/stimulus";
require("/node_modules/pnotify/dist/PNotifyBrightTheme.css");

function ReportsModule($scope) {
  this.$scope = $scope;
  this.$formModal = $scope.find("#modal-form");
  this.$configReportModal = $scope.find("#modal-config-report");
  this.$btnChangeStatusVoucher = $scope.find(".change-status-voucher-btn");

  this.$btnOperarionsByOperatorReport = $scope.find(
    ".report-operations-by-operator-btn"
  );
  this.$btnReportConfig = $scope.find(
    ".btn-config-export"
  );
  

  this.$btnGenerateReport = $scope.find("#btn-generate-report");
}
ReportsModule.prototype = {
  $scope: null,
  $formModal: null,
  $configReportModal: null,
  $table: null,
  $btnChangeStatusVoucher: ".change-status-voucher-btn",
  $btnOperarionsByOperatorReport: ".report-operations-by-operator-btn",
  $btnReportConfig: ".btn-config-export",
  $btnGenerateReport: "#btn-generate-report",

  constructor: ReportsModule,
  init: function () {
    this.initListeners();
  },

  initListeners: function () {
    let _this = this;

    _this.$btnOperarionsByOperatorReport.click(function (e) {
      console.log('loading reports module');
      let targetBtn = this;
      _this.loadConfigReportFormModal(
        $(this).data("action-url"),
        "",
        "",
        $(this).data("modal-title")
      );
      //evt.preventDefault();
      //evt.stopPropagation();
    });    

    _this.$btnChangeStatusVoucher.click(function (e) {
      console.log("anular desde el voycher con scope nuevo");
      let targetBtn = this;
      let ajaxAction = function (btn) {
        $.ajax({
          url: $(btn).data("action-url"),
          type: "GET",
        })
          .done(function (res) {
            Swal.fire({
              title: "Atención!!",
              text: "Se ha enviado un mensaje al correo del titular para completar la operación",
              icon: "info",
            });
            /*if (res.actionValid) {
              if (res.msg) {
                Swal.fire({
                  title: res.title,
                  text: res.msg,
                  icon: "info",
                });
              }
              _this.$formModal.modal("hide");
              _this.$table.DataTable().ajax.reload();
            } else {
              Swal.fire({
                title: res.title,
                //text: res.msg,
                html: res.msg,
                icon: "warning",
              });
            }
            */
          })
          .fail(function (res) {
            console.log("fail");
            console.log(res.responseJSON.title);
            Swal.fire({
              title: res.responseJSON.title + "!!",
              text: res.responseJSON.msg,
              icon: "error",
            });
          })
          .always(function () {
            $.LoadingOverlay("hide");
          });
      };
      if ($(targetBtn).data("make-confirmation")) {
        Swal.fire({
          title: $(targetBtn).data("confirmation-msg-title"),
          text: $(targetBtn).data("confirmation-msg-desc"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: $(targetBtn).data("confirmation-msg-btn-ok"),
          cancelButtonText: $(targetBtn).data("confirmation-msg-btn-cancel"),
        }).then(function (confirmed) {
          if (confirmed.value) {
            ajaxAction(targetBtn);
          }
        });
      } else {
        ajaxAction(targetBtn);
      }
    });

    _this.$btnGenerateReport.click(function (evt) {
      let $form = _this.$configReportModal.find("form");
      if ($form.valid()) {
        //$form.trigger("submit");
        $.LoadingOverlay("show");
        let btn = evt.target;
        $.ajax({
          url: $($form).attr("action"),
          type: $($form).attr("method"),
          data: $($form).serialize(),
          xhrFields: {
            responseType: "blob",
          },
        })
          .done(function (data, status, xhr) {

            let filename = "";
            let disposition = xhr.getResponseHeader("Content-Disposition");
            if (disposition && disposition.indexOf("attachment") !== -1) {
              let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              let matches = filenameRegex.exec(disposition);
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, "");
              }
            }

            let reader = new FileReader();
            reader.onload = function (e) {
              let link = document.createElement("a");
              link.href = e.target.result;
              link.download = filename;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            };
            reader.readAsDataURL(data);

            _this.$configReportModal.modal("hide");
          })
          .fail(function (res) {
            Swal.fire({
              title: "Oops!!",
              text: JSON.parse(res.content)["msg"],
              icon: "error",
            });
          })
          .always(function () {
            $.LoadingOverlay("hide");
          });
      }
    });
  },

  loadConfigReportFormModal: function (url, type, silent, titleModal) {
    let _this = this;
    $(_this.$configReportModal).addClass("modal-fade");
    // _this.toggleBodyBlock();
    let $modalBody = $(".modal-body", _this.$configReportModal);
    if (titleModal != null) {
      _this.$configReportModal.find(".modal-title").text(titleModal);
    }
    if (silent !== true) {
      $.LoadingOverlay("show");
    } else {
      //$modalBody.html(_this.loadPlaceholder);
    }
    $modalBody.load(url, function (res) {
      App.initComponents($modalBody);
      _this.$configReportModal.trigger("api_form_modal_loaded", { type: type });
      if (silent !== true) {
        $.LoadingOverlay("hide");
        _this.$configReportModal.modal("show");
      }
    });
  },
};

(function () {
  let $scopeReports = $(".scope-reports");
  if ($scopeReports.length) {
    new ReportsModule($scopeReports).init();
  }
})(jQuery, window, document);
