import Chart from "chart.js/auto";

function DashboardModule($scope) {
  this.$scope = $scope;
  this.$formModal = $scope.find("#modal-form");
  //this.$productViewerModal = $scope.find("#modal-product-viewer");
  this.$table = $scope.find(".crud-table");
  //this.$containerTableMostSaledProducts = $scope.find(".most-selled-products-table-container");
  //this.$timeLineOrdersContainer = $scope.find(".timeline_last_orders_container");

  //this.$selectDashboardSalesResumeAgencies = $scope.find('.select-dashboard-sales-resume-agencies');
  //this.$selectDashboardSalesResumeDateRange = $scope.find('.select-dashboard-sales-resume-date-range');

  this.chartLineSales = $scope.find("#sales-resume-chart");
  //this.$selectDashboardSalesChartAgencies = $scope.find('.select-dashboard-sales-chart-agencies');
  //this.$selectDashboardSalesChartDateRange = $scope.find('.select-dashboard-sales-chart-date-range');

  //this.$selectDashboardOrdersResumeAgencies = $scope.find('.select-dashboard-orders-resume-agencies');
  //this.$selectDashboardOrdersResumeDateRange = $scope.find('.select-dashboard-orders-resume-date-range');

  //this.chartLineOrders = $scope.find('#orders-resume-chart');
  //this.$selectDashboardOrdersChartAgencies = $scope.find('.select-dashboard-orders-chart-agencies');
  //this.$selectDashboardOrdersChartDateRange = $scope.find('.select-dashboard-orders-chart-date-range');

  //this.$selectDashboardMostSelledProductsAgencies = $scope.find('.select-dashboard-selled-products-table-agencies');
  //this.$selectDashboardMostSelledProductsDateRange = $scope.find('.select-dashboard-selled-products-table-date-range');
}
DashboardModule.prototype = {
  $scope: null,
  $formModal: null,
  $table: null,
  //$containerTableMostSaledProducts: null,

  //$chartDailyOrders: $('#chart_daily_orders'),
  //$chartAgencyOrders: $('#chart_agencies_orders'),

  $chartLineSales: $("#sales-resume-chart"),

  $dashboardServicesRequestsResumeBox:
    ".dashboard-services-requests-resume-box",
  //$selectDashboardSalesResumeAgencies: '.select-dashboard-sales-resume-agencies',
  //$selectDashboardSalesResumeDateRange: '.select-dashboard-sales-resume-date-range',
  $dashboardServicesRequestsResumeBoxContentContainer:
    ".dashboard-services-requests-resume-box-content-container",

  //$selectDashboardSalesChartAgencies: '.select-dashboard-sales-chart-agencies',
  //$selectDashboardSalesChartDateRange: '.select-dashboard-sales-chart-date-range',

  //$chartLineOrders: $('#orders-resume-chart'),

  //$dashboardOrdersResumeBox: '.dashboard-orders-resume-box',
  //$selectDashboardOrdersResumeAgencies: '.select-dashboard-orders-resume-agencies',
  //$selectDashboardOrdersResumeDateRange: '.select-dashboard-orders-resume-date-range',
  //$dashboardOrdersResumeBoxContentContainer: '.orders-resume-box-content-container',

  //$selectDashboardOrdersChartAgencies: '.select-dashboard-orders-chart-agencies',
  //$selectDashboardOrdersChartDateRange: '.select-dashboard-orders-chart-date-range',

  //$selectDashboardMostSelledProductsAgencies: '.select-dashboard-selled-products-table-agencies',
  //$selectDashboardMostSelledProductsDateRange: '.select-dashboard-selled-products-table-date-range',
  //$dashboardMostSelledProductsContentContainer: '.most-selled-products-table-container',

  constructor: DashboardModule,

  init: function () {
    let _this = this;
    console.log('loading dashboard module');
    this.loadServicesRequestResumeBoxContent();
    //this.loadOrdersResumeBoxContent();
    //this.loadMostSelledProductsContent();
    this.initListeners();
    this.initCharts();
    //this.loadTableRequests();
  },

  loadFormModal: function (url, type, silent) {
    let _this = this;
    // _this.toggleBodyBlock();
    let $modalBody = $(".modal-body", _this.$formModal);
    if (silent !== true) {
      $.LoadingOverlay("show");
    } else {
      //$modalBody.html(_this.loadPlaceholder);
    }
    $modalBody.load(url, function (res) {
      App.initComponents($modalBody);
      _this.$formModal.trigger("api_form_modal_loaded", { type: type });
      if (silent !== true) {
        $.LoadingOverlay("hide");
        _this.$formModal.modal("show");
      }
    });
  },

  initListeners: function () {
    let _this = this;

    //sales listeners
    /*_this.$selectDashboardSalesResumeDateRange.on("change", function(evt) {
            _this.loadSalesResumeBoxContent();
        });
        _this.$selectDashboardSalesResumeAgencies.on("change", function(evt) {
            _this.loadSalesResumeBoxContent();
        });

        _this.$selectDashboardSalesChartDateRange.on("change", function(evt) {
            _this.updateSalesLineChart(_this.$chartLineSales, $(this).data('load-url'));
        });
        _this.$selectDashboardSalesChartAgencies.on("change", function(evt) {
            _this.updateSalesLineChart(_this.$chartLineSales, $(this).data('load-url'));
        });

        //orders listeners
        _this.$selectDashboardOrdersResumeDateRange.on("change", function(evt) {
            _this.loadOrdersResumeBoxContent();
        });
        _this.$selectDashboardOrdersResumeAgencies.on("change", function(evt) {
            _this.loadOrdersResumeBoxContent();
        });

        _this.$selectDashboardOrdersChartDateRange.on("change", function(evt) {
            _this.updateOrdersLineChart(_this.$chartLineOrders, $(this).data('load-url'));
        });
        _this.$selectDashboardOrdersChartAgencies.on("change", function(evt) {
            _this.updateOrdersLineChart(_this.$chartLineOrders, $(this).data('load-url'));
        });

        //most products table listeners
        _this.$selectDashboardMostSelledProductsDateRange.on("change", function(evt) {
            _this.loadMostSelledProductsContent();
        });
        _this.$selectDashboardMostSelledProductsAgencies.on("change", function(evt) {
            _this.loadMostSelledProductsContent();
        });

        _this.$containerTableMostSaledProducts.on("click", ".product_lite_viewer_link", function(e) {
            //detengo action link
            e.preventDefault();
            _this.loadProductViewerModal(
                $(this).data("load-url"),
                "view",
                "Ver Producto"
            );
        });*/
  },

  initCharts: function () {
    let _this = this;
    /*
        _this.$chartLineSales.each(function(index) {
            _this.$chartLineSales = new Chart($(this), {
                type: 'line',
                data: null,
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                            //position: 'top',
                        },
                    },
                    interaction: {
                        intersect: false,
                    },

                    scales: {
                        x: {
                            display: true,
                            title: {
                                display: true
                            }
                        },
                        y: {
                            display: true,
                            title: {
                                display: true,
                                text: 'Ventas'
                            },
                            suggestedMin: 0,
                            suggestedMax: 200
                        }
                    }
                },
            });
            _this.updateSalesLineChart(_this.$chartLineSales, $(this).data('url'));
        });*/
    /*
        _this.$chartLineOrders.each(function(index) {
            _this.$chartLineOrders = new Chart($(this), {
                type: 'bar',
                data: null,
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                            //position: 'top',
                        },
                    },
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true,
                            title: {
                                display: true,
                                text: 'Órdenes'
                            },
                        }
                    }
                },
            });
            _this.updateOrdersLineChart(_this.$chartLineOrders, $(this).data('url'));
        });
        */

    /*
        _this.$chartDailyOrders.each(function(index) {
            var chartDailyOrders = new Chart($(this), {
                type: 'line',
                data: null,
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                            //position: 'top',
                        },
                    }
                },
            });
            _this.updateChart(chartDailyOrders, $(this).data('url'));
        });

        _this.$chartAgencyOrders.each(function(index) {
            var chartAgencyOrders = new Chart($(this), {
                //type: 'doughnut',
                type: 'bar',
                data: null,
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                            //position: 'left',
                        },
                    }
                },
            });
            _this.updateChart(chartAgencyOrders, $(this).data('url'));
        });
        */
  },
  /*
  loadTableRequests: function () {
    let _this = this;
    $(_this.$timeLineOrdersContainer).LoadingOverlay("show");
    $.ajax({
      url: $(_this.$timeLineOrdersContainer).data("load-url"),
      method: "GET",
    })
      .done(function (res) {
        $(_this.$timeLineOrdersContainer).html(res);
      })
      .fail(function (errorRes) {})
      .always(function () {
        $(_this.$timeLineOrdersContainer).LoadingOverlay("hide");
      });
  },
  */
  /*
    updateChart: function(objChart, dataUrl) {
        $(objChart.canvas).LoadingOverlay('show');
        $.ajax({
                url: dataUrl,
                method: "GET",
            })
            .done(function(res) {
                objChart.data.datasets = res.datasets;
                objChart.data.labels = res.labels;
                objChart.update();
                //$(this).LoadingOverlay('hide');
            })
            .fail(function(errorRes) {})
            .always(function() {
                $(objChart.canvas).LoadingOverlay('hide');
            });
    },
    */
  updateSalesLineChart: function (objChart, dataUrl) {
    $(objChart.canvas).LoadingOverlay("show");
    $.ajax({
      url: dataUrl,
      method: "GET",
      data: {
        agency: $(this.$selectDashboardSalesChartAgencies).val(),
        daterange: $(this.$selectDashboardSalesChartDateRange).val(),
      },
    })
      .done(function (res) {
        objChart.data.datasets = res.datasets;
        objChart.data.labels = res.labels;
        objChart.update();
        //$(this).LoadingOverlay('hide');
      })
      .fail(function (errorRes) {})
      .always(function () {
        $(objChart.canvas).LoadingOverlay("hide");
      });
  },

  /*
  updateOrdersLineChart: function (objChart, dataUrl) {
    $(objChart.canvas).LoadingOverlay("show");
    $.ajax({
      url: dataUrl,
      method: "GET",
      data: {
        agency: $(this.$selectDashboardOrdersChartAgencies).val(),
        daterange: $(this.$selectDashboardOrdersChartDateRange).val(),
      },
    })
      .done(function (res) {
        objChart.data.datasets = res.datasets;
        objChart.data.labels = res.labels;
        objChart.update();
        //$(this).LoadingOverlay('hide');
      })
      .fail(function (errorRes) {})
      .always(function () {
        $(objChart.canvas).LoadingOverlay("hide");
      });
  },
*/
  loadServicesRequestResumeBoxContent: function () {
    console.log("recarga box content");
    let $dashboardServicesRequestsResumeBox = $(
      this.$dashboardServicesRequestsResumeBox
    );
    let $dashboardServicesRequestsResumeBoxContentContainer = $(
      this.$dashboardServicesRequestsResumeBoxContentContainer
    );
    $dashboardServicesRequestsResumeBox.LoadingOverlay("show");
    //$dashboardServicesRequestsResumeBoxContentContainer.LoadingOverlay("show");

    $.ajax({
      url: $(this.$dashboardServicesRequestsResumeBoxContentContainer).data(
        "load-url"
      ),
      method: "GET",
      data: {
        //'agency': $(this.$selectDashboardSalesResumeAgencies).val(),
        //'daterange': $(this.$selectDashboardSalesResumeDateRange).val()
      },
    })
      .done(function (res) {
        $dashboardServicesRequestsResumeBoxContentContainer.html(res);
      })
      .fail(function (res) {
        Swal.fire({
          title: "Oops!",
          text: "Something went wrong while executing this task, please try again later o contact support service.",
          icon: "error",
        });
      })
      .always(function (res) {
        console.log(res.statusCode);
        if (res.statusCode > 399) {
          Swal.fire({
            title: JSON.parse(res.content)["title"],
            text: JSON.parse(res.content)["msg"],
            icon: "error",
          });
        }
        //console.log(res);

        console.log(res);
        $dashboardServicesRequestsResumeBox.LoadingOverlay("hide");
      });
  },

  /*
  loadOrdersResumeBoxContent: function () {
    let $ordersResumeBoxContentContainer = $(
      this.$dashboardOrdersResumeBoxContentContainer
    );
    $ordersResumeBoxContentContainer.LoadingOverlay("show");
    $.ajax({
      url: $(this.$selectDashboardOrdersResumeDateRange).data("load-url"),
      method: "GET",
      data: {
        agency: $(this.$selectDashboardOrdersResumeAgencies).val(),
        daterange: $(this.$selectDashboardOrdersResumeDateRange).val(),
      },
    })
      .done(function (res) {
        $ordersResumeBoxContentContainer.html(res);
        $ordersResumeBoxContentContainer.LoadingOverlay("hide");
      })
      .fail(function (errorRes) {
        //$ordersResumeBoxContentContainer.LoadingOverlay("hide");
      })
      .always(function () {
        //$ordersResumeBoxContentContainer.LoadingOverlay("hide");
      });
  },
*/
  /*
  loadMostSelledProductsContent: function () {
    let $mostSelledProductsContentContainer = $(
      this.$dashboardMostSelledProductsContentContainer
    );
    $mostSelledProductsContentContainer.LoadingOverlay("show");
    $.ajax({
      url: $(this.$selectDashboardMostSelledProductsDateRange).data("load-url"),
      method: "GET",
      data: {
        agency: $(this.$selectDashboardMostSelledProductsAgencies).val(),
        daterange: $(this.$selectDashboardMostSelledProductsDateRange).val(),
      },
    })
      .done(function (res) {
        $mostSelledProductsContentContainer.html(res);
        $mostSelledProductsContentContainer.LoadingOverlay("hide");
      })
      .fail(function (errorRes) {
        //$ordersResumeBoxContentContainer.LoadingOverlay("hide");
      })
      .always(function () {
        //$ordersResumeBoxContentContainer.LoadingOverlay("hide");
      });
  },
*/
  /*
  loadProductViewerModal: function (url, type, silent) {
    let _this = this;
    // _this.toggleBodyBlock();
    let $modalBody = $(".modal-body", _this.$productViewerModal);
    console.log($modalBody);
    if (silent !== true) {
      $.LoadingOverlay("show");
    } else {
      //$modalBody.html(_this.loadPlaceholder);
    }
    $modalBody.load(url, function (res) {
      App.initComponents($modalBody);
      _this.$productViewerModal.trigger("api_form_modal_loaded", {
        type: type,
      });
      if (silent !== true) {
        $.LoadingOverlay("hide");
        _this.$productViewerModal.modal("show");
      }
    });
  },
  */
};

(function () {
  let $scopeDashboard = $(".scope-dashboard");
  if ($scopeDashboard.length) {
    new DashboardModule($scopeDashboard).init();
  }
})(jQuery, window, document);
