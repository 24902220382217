function CommonModule($scope) {
    this.$scope = $scope;
    this.$allModals = $scope.find(".modal");
    this.formModalChangePassword = $scope.find(".modal-change-pass-form");
    this.$userDetailsModal = $scope.find(".modal-user-details");
    this.$notificationsBarChangeUserPassBTN = $scope.find(
        ".notifications_bar_change_user_password_btn"
    );
    this.$notificationsBarUserDetailsBTN = $scope.find(
        ".notifications_bar_user_details_btn"
    );
    this.$btnSaveModalChangePassw = this.formModalChangePassword.find(
        ".btn-save-change-passw"
    );
    this.$btnTableRefresh = $scope.find(".btn-table-refresh");
}
CommonModule.prototype = {
    $allModals: null,
    formModalChangePassword: null,
    $notificationsBarChangeUserPassBTN: null,
    $notificationsBarUserDetailsBTN: null,
    $btnSaveModalChangePassw: null,
    $btnTableRefresh: null,

    constructor: CommonModule,
    init: function() {
        console.log('loading common module');
        this.initListeners();
        /*new PNotify({
            title: 'System notice',
            text: 'The record has been processed.',
            type: 'success'
        });*/

    },

    loadFormModal: function(url, title, modalScope, silent) {
        let _this = this;
        //console.log(_this.$scope);
        $(_this.formModalChangePassword).data("modal-scope", modalScope);
        //console.log($(_this.formModalChangePassword));
        let $modalTitle = $(".titleModalLabel", _this.formModalChangePassword);
        $modalTitle.html(title);
        let $modalBody = $(".modal-body", _this.formModalChangePassword);
        _this.formModalChangePassword.modal("show");

        //console.log(_this.formModalChangePassword);
        if (silent !== true) {
            $.LoadingOverlay("show");
        } else {
            //$modalBody.html(_this.loadPlaceholder);
        }
        $modalBody.load(url, function(res) {
            App.initComponents($modalBody);
            _this.formModalChangePassword.trigger("api_form_modal_loaded", {
                modalScope: modalScope,
            });
            if (silent !== true) {
                $.LoadingOverlay("hide");
                _this.formModalChangePassword.modal("show");
                $(".modal-backdrop").hide();
            }
        });
    },

    initListeners: function() {
        let _this = this;

        _this.$btnTableRefresh.on("click", function(evt){
            console.log("update table");
            console.log($(this));
            let $table = $(this).closest(".kt-portlet").find("table").first();
            console.log($table);
            $table.DataTable().ajax.reload()

        })

        _this.$allModals.on('api_form_modal_loaded', function (evt) {
            console.log("cargado un modal");
            let $form = $(this).find('form');
            $form.validate({
                ignore: ":not(:visible),:disabled",
                errorElement: "span",
                errorPlacement: function (error, element) {
                    error.addClass("mt-2 mb-2 text-danger validation-error");
                    if (element.prop("type") === "checkbox") {
                        error.insertAfter(element.parent("label"));
                    } else if (element.prop("type") === "radio") {
                        error.insertAfter(element.parents(".icheck"));
                    } else if (element.is('select') && (element.hasClass('select2') || element.hasClass('select2-ph'))) {
                        error.insertAfter(element.siblings(".select2"));
                    } else if (element.is('textarea') && element.hasClass('lc-ckeditor')) {
                        error.insertAfter(element.siblings(".ck-editor"));
                    } else if (element.is('input') && element.parents('.input-group').length > 0) {
                        error.insertAfter(element.parents('.input-group'));
                    } else {
                        error.insertAfter(element);
                    }
                },
                highlight: function (element, errorClass, validClass) {
                    $(element).addClass("is-invalid").removeClass("is-valid");
                },
                unhighlight: function (element, errorClass, validClass) {
                    $(element).addClass("is-valid").removeClass("is-invalid");
                },
                submitHandler: function (form) {
                    _this.toggleBodyBlock()
                    $.ajax(
                        {
                            url: $(form).prop('action'),
                            method: 'post',
                            data: new FormData(form),
                            contentType: false,
                            processData: false,
                            success: function (res) {
                                _this.dt.ajax.reload();
                                $(this).modal('hide');
                                toastr.success('The record has been processed.')
                                $('body').trigger('crud_form_processed', [$(form).prop('name')]);
                            },
                            error: function (res) {
                                $('.modal-body', $(this)).html(res.responseText);
                                App.initComponents($(this).find('.modal-body'));
                                $(this).trigger('api_form_modal_loaded');
                            },
                            complete: function () {
                                _this.toggleBodyBlock()
                            }
                        }
                    );
                    return false;
                }

            });
            _this.$clientDataContainer = $('.new-client-container', $(this));
        });

        _this.$notificationsBarUserDetailsBTN.click(function(evt) {
            evt.preventDefault();
            _this.$userDetailsModal.modal("show");
            $(".modal-backdrop").hide();
        });

        _this.$notificationsBarChangeUserPassBTN.click(function(evt) {
            evt.preventDefault();
            _this.loadFormModal(
                $(this).data("load-url"),
                "Change Password",
                "common"
            );
        });

        _this.$btnSaveModalChangePassw.click(function() {
            let $form = _this.formModalChangePassword.find("form");
            if ($form.valid()) {
                $.ajax({
                        url: $form.attr("action"),
                        method: "POST",
                        data: $form.serialize(),
                    })
                    .done(function(res) {
                        _this.formModalChangePassword.modal("hide");
                        Swal.fire({
                            title: _this.$notificationsBarChangeUserPassBTN.data(
                                "confirmation-msg-title"
                            ),
                            text: _this.$notificationsBarChangeUserPassBTN.data(
                                "confirmation-msg-desc"
                            ),
                            icon: "info",
                            showCancelButton: false,
                            confirmButtonText: "OK", //$(el).data("confirmation-msg-btn-ok"),
                        });
                    })
                    .fail(function(errorRes) {
                        capa = _this.formModalChangePassword.find("form").closest('div');
                        capa.html(errorRes.responseText);
                    })
                    .always(function() {
                        $(_this.$projectTableTaskContainer).LoadingOverlay("hide");
                    });
            }
        });
    },
};

(function() {
    let $scopeAdmin = $(".scope-admin");
    let $scopeDashboard = $(".scope-dashboard");
    let $scopeApp = $(".scope-app");
    let $scopeClient = $(".scope-client");
    if ($scopeAdmin.length) {
        new CommonModule($scopeAdmin).init();
    }
    if ($scopeDashboard.length) {
        new CommonModule($scopeDashboard).init();
    }
    if ($scopeApp.length) {
        new CommonModule($scopeApp).init();
    }
    if ($scopeClient.length) {
        new CommonModule($scopeClient).init();
    }

})(jQuery, window, document);