import Tree from "@widgetjs/tree";

//notificaciones con toastr
import * as Toastr from "toastr";

//mapas
import L from "leaflet";

require("/node_modules/leaflet/dist/leaflet.css");

// Importa Leaflet Routing Machine y sus estilos
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine";

//notificaciones con PNotify, pero hay que importar directamente en el fichero
import PNotify from "/node_modules/pnotify/dist/es/PNotify.js";
import {add} from "@hotwired/stimulus";

require("/node_modules/pnotify/dist/PNotifyBrightTheme.css");

function AppModule($scope) {
    this.$scope = $scope;
    this.$formModal = $scope.find("#modal-form");
    this.$mapModal = $scope.find("#modal-map");
    this.$personFormModal = $scope.find("#modal-form-person");
    this.$evaluateRequestModal = $scope.find("#modal-evaluate-request-form");
    this.$historyViewerModal = $scope.find("#modal-history-viewer");
    this.$table = $scope.find(".crud-table");
    this.$controlServiceRequestTypeFilter = $scope.find(
        '[name="filter_service_request_type"]'
    );
    this.$filterModal = $scope.find("#filter-form");
    this.$formFilter = this.$filterModal.find("form");

    //this.$btnChangeStatusRecord = $scope.find(".change-status-record-btn");
    this.$btnChangeStatusRecord = ".change-status-record-btn";
    this.$btnChangeStatusPaidRecord = ".change-status-paid-record-btn";
    this.$btnEvaluateRequest = ".evaluate-request-btn";
    this.$btnChangeStatusVoucher = $scope.find(".change-status-voucher-btn");
    this.$btnBasicExport = $scope.find(".btn-basic-export");

    this.$checkMultiselectionCrudTable = $scope.find(
        ".crud-multiselection-check"
    );
    this.$btnRapidEnable = $scope.find(".rapid-enable-btn");
    this.$btnRapidDisable = $scope.find(".rapid-disable-btn");

    this.$ctLeftPanel = $scope.find(".ct-left-panel");
    this.$ctListBtn = $scope.find(".ct-list-btn");
    //this.$btnAddressMap = $scope.find(".btn-select-map-point");
    //this.$btnViewRecordHistory = $scope.find(".history-viewer-record-btn");

    this.$mapDiv = "#map";
    this.$mapSelectPlaceCoordinates = ".map-select-place-coordinates";
    this.$mapRoute = null;
    this.$mapPlace = null;
}

AppModule.prototype = {
    $scope: null,
    $formModal: null,
    $evaluateRequestModal: null,
    $table: null,
    $controlServiceRequestTypeFilter: null,
    $filterModal: null,
    $historyViewerModal: null,
    $formFilter: null,
    $controlDestinationsTree: null,
    $temporalOrigen: null,
    $btnCollectionRemoveItem: ".collection-remove-item",
    $collectionList: ".collection-list",

    $selectUserRoles: "#user_roles",
    $selectUserAgencies: "#user_agencies",

    $btnChangeOrderStatus: ".change-ordes-status-btn",
    $btnSaveChangeStatus: "#btn-save-change-status",
    $btnCancelOrder: ".cancel-order-btn",

    $btnChangeStatusRecord: ".change-status-record-btn",
    $btnChangeStatusPaidRecord: ".change-status-paid-record-btn",
    $btnChangeStatusVoucher: ".change-status-voucher-btn",

    $mapDiv: "#map",
    $mapSelectPlaceCoordinates: ".map-select-place-coordinates",

    $vehicleImageSelector: ".vehicle-image-selector",
    $selectVehiclePrototype: ".vehicle-image-option",
    $seatingCapacityText: ".seating-capacities-text",
    $occupiedCapacityText: ".occupied-capacities-text",
    $availableCapacityText: ".available-capacity-text",
    $inputPrivateServiceQuantityPersons: ".private_service_quantity_persons",

    $inputPlacePassengerCuantity: ".place-passenger-cuantity",
    $selectPlaceType: ".request-place-type",
    $selectPredefinedPlace: ".request-predefined-place",
    $inputPlaceCoordinates: ".request-place-coordinates",
    $inputPlaceAddress: ".request-place-address",
    $selectPlaceLocation: ".request-place-location",
    $btnAddressMap: ".btn-select-map-point",

    $select2RequestOrigin: ".resquest-origin-field",
    $select2RequestDestination: ".resquest-destination-field",
    $select2RequestRoute: ".resquest-route-field",
    $originSelect: ".resquest-origin-field",
    $destinationSelect: ".resquest-destination-field",

    $btnAproveRequest: "#btn-aprove-request",
    $btnRejectRequest: "#btn-reject-request",
    $btnSaveRequestModal: "#btn-save-request",
    $btnCalculatePrice: ".calculate-price-button",
    $inputCalculatedPrice: ".calculated-price-field",
    $inputToPayPrice: ".to-pay-price-field",
    $btnNewHolderPerson: ".btn-new-holder-person",
    $btnSavePersonModal: "#btn-save-person",
    $selectServiceRequestOwner: ".service-request-owner",
    $selectSourceServiceRequest: ".service-request-source-request",
    $selectExternalSourceServiceRequest: ".service-request-external-provider",
    $btnSetPriceToPay: ".set-price-to-pay-button",
    $mapRoute: null,
    $mapPlace: null,
    $btnViewRecordHistory: ".history-viewer-record-btn",
    $selectRequestPlaceProvince: ".request-place-province",
    $selectRequestPlaceMunicipality: ".request-place-municipality",
    $selectRequestPlaceLocation: ".request-place-location",


    constructor: AppModule,
    init: function () {
        console.log('loading app module');
        this.initListeners();
    },

    loadFormModal: function (url, type, silent) {
        let _this = this;
        // _this.toggleBodyBlock();
        let $modalBody = $(".modal-body", _this.$formModal);
        if (silent !== true) {
            $.LoadingOverlay("show");
        } else {
            //$modalBody.html(_this.loadPlaceholder);
        }
        $modalBody.load(url, function (res) {
            App.initComponents($modalBody);
            _this.$formModal.trigger("api_form_modal_loaded", {type: type});
            if (silent !== true) {
                $.LoadingOverlay("hide");
                _this.$formModal.modal("show");
            }
        });
    },

    loadMapModal: function (
        originShow,
        inputPlaceCoordinates,
        currentCoordinates,
        inputLocation
    ) {
        console.log(originShow);
        console.log(inputPlaceCoordinates);
        console.log(currentCoordinates);
        console.log(inputLocation);
        let _this = this;
        $(_this.$formModal).addClass("modal-fade"); //cuando levanta desde un form modal
        $(_this.$evaluateRequestModal).addClass("modal-fade"); //cuando levanta en el evaluate modal
        // _this.toggleBodyBlock();
        let $modalBody = $(".modal-body", _this.$mapModal);

        $(_this.$mapModal).addClass(originShow);
        $(_this.$mapModal).attr("id-coordinates-field", inputPlaceCoordinates);
        $(_this.$mapModal).attr("current-coordinates-field", currentCoordinates);
        $(_this.$mapModal).attr("id-location-field", inputLocation);
        /*
        $modalBody.load(url, function (res) {
          App.initComponents($modalBody);
          _this.$formModal.trigger("api_form_modal_loaded", { type: type });
          //if (silent !== true) {
          //$.LoadingOverlay("hide");
          //_this.$formModal.modal("show");
          //}
        });*/
        _this.$mapModal.modal("show");

    },

    initListeners: function () {
        let _this = this;

        //////ahora////
        _this.$formModal.on("click", _this.$btnAddressMap, function (e) {
            let placetype = null;
            if (
                $(this).closest(".collection-container").find("#pickupLocations-list")
                    .length > 0
            ) {
                placetype = "pickup-place";
            } else if (
                $(this).closest(".collection-container").find("#arrivalLocations-list")
                    .length > 0
            ) {
                placetype = "arrival-place";
            } else {
                console.log("bum");
            }
            _this.loadMapModal(
                placetype,
                $(this)
                    .closest(".collection-item")
                    .find(_this.$inputPlaceCoordinates)
                    .first()
                    .attr("id"),
                $(this)
                    .closest(".collection-item")
                    .find(_this.$inputPlaceCoordinates)
                    .first()
                    .val(),
                $(this)
                    .closest(".collection-item")
                    .find(_this.$selectPlaceLocation)
                    .first()
                    .attr("id")
            );
        });

        _this.$controlServiceRequestTypeFilter.change(function (evt) {
            _this.$formFilter
                .find(".filter_service_request_type")
                .val($(this).val())
                .trigger("change");
            _this.$table.DataTable().ajax.reload();
        });

        /////fin ahora///////
        _this.$btnChangeStatusVoucher.click(function (e) {
            console.log("anular desde el voycher");
            let targetBtn = this;
            let ajaxAction = function (btn) {
                $.ajax({
                    url: $(btn).data("action-url"),
                    type: "GET",
                })
                    .done(function (res) {
                        Swal.fire({
                            title: "Atención!!",
                            text: "Se ha enviado un mensaje al correo del titular para completar la operación",
                            icon: "info",
                        });
                        /*if (res.actionValid) {
                          if (res.msg) {
                            Swal.fire({
                              title: res.title,
                              text: res.msg,
                              icon: "info",
                            });
                          }
                          _this.$formModal.modal("hide");
                          _this.$table.DataTable().ajax.reload();
                        } else {
                          Swal.fire({
                            title: res.title,
                            //text: res.msg,
                            html: res.msg,
                            icon: "warning",
                          });
                        }
                        */
                    })
                    .fail(function (res) {
                        console.log("fail");
                        console.log(res.responseJSON.title);
                        Swal.fire({
                            title: res.responseJSON.title + "!!",
                            text: res.responseJSON.msg,
                            icon: "error",
                        });
                    })
                    .always(function () {
                        $.LoadingOverlay("hide");
                    });
            };
            if ($(targetBtn).data("make-confirmation")) {
                Swal.fire({
                    title: $(targetBtn).data("confirmation-msg-title"),
                    text: $(targetBtn).data("confirmation-msg-desc"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: $(targetBtn).data("confirmation-msg-btn-ok"),
                    cancelButtonText: $(targetBtn).data("confirmation-msg-btn-cancel"),
                }).then(function (confirmed) {
                    if (confirmed.value) {
                        ajaxAction(targetBtn);
                    }
                });
            } else {
                ajaxAction(targetBtn);
            }
        });
        _this.$table.on("click", _this.$btnEvaluateRequest, function (e) {
            let targetBtn = this;
            _this.loadEvaluateRequestModal(
                $(this).data("action-url"),
                "edit",
                $(this).data("title-modal")
            );
        });

        _this.$table.on("click", _this.$btnChangeStatusPaidRecord, function (e) {
            console.log("cambiar estado del pago");
            let targetBtn = this;
            let ajaxAction = function (btn) {
                $.ajax({
                    url: $(btn).data("action-url"),
                    type: "GET",
                })
                    .done(function (res) {
                        if (res.ok) {
                            toastr.success(
                                JSON.parse(res.content)["msg"],
                                JSON.parse(res.content)["title"],
                                {
                                    timeOut: 3000,
                                    //progressBar : true,
                                    newestOnTop: true,
                                }
                            );
                            //PNotify.alert('con pnotify');
                            _this.$table.DataTable().ajax.reload();
                        } else {
                            Swal.fire({
                                title: JSON.parse(res.content)["title"],
                                text: JSON.parse(res.content)["msg"],
                                icon: "error",
                            });
                        }
                    })
                    .fail(function (res) {
                        Swal.fire({
                            title: "Oops!!",
                            text: JSON.parse(res.content)["msg"],
                            icon: "error",
                        });
                    })
                    .always(function () {
                        $.LoadingOverlay("hide");
                    });
            };
            if ($(targetBtn).data("make-confirmation")) {
                Swal.fire({
                    title: $(targetBtn).data("confirmation-msg-title"),
                    text: $(targetBtn).data("confirmation-msg-desc"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: $(targetBtn).data("confirmation-msg-btn-ok"),
                    cancelButtonText: $(targetBtn).data("confirmation-msg-btn-cancel"),
                }).then(function (confirmed) {
                    if (confirmed.value) {
                        ajaxAction(targetBtn);
                    }
                });
            } else {
                ajaxAction(targetBtn);
            }
        });

        _this.$table.on("click", _this.$btnChangeStatusRecord, function (e) {
            console.log("anular desde la tabla");
            let targetBtn = this;
            let ajaxAction = function (btn) {
                $.ajax({
                    url: $(btn).data("action-url"),
                    type: "GET",
                })
                    .done(function (res) {
                        if (res.ok) {
                            toastr.success(
                                JSON.parse(res.content)["msg"],
                                JSON.parse(res.content)["title"],
                                {
                                    timeOut: 3000,
                                    //progressBar : true,
                                    newestOnTop: true,
                                }
                            );
                            //PNotify.alert('con pnotify');
                            _this.$table.DataTable().ajax.reload();
                        } else {
                            Swal.fire({
                                title: JSON.parse(res.content)["title"],
                                text: JSON.parse(res.content)["msg"],
                                icon: "error",
                            });
                        }
                    })
                    .fail(function (res) {
                        Swal.fire({
                            title: "Oops!!",
                            text: JSON.parse(res.content)["msg"],
                            icon: "error",
                        });
                    })
                    .always(function () {
                        $.LoadingOverlay("hide");
                    });
            };
            if ($(targetBtn).data("make-confirmation")) {
                Swal.fire({
                    title: $(targetBtn).data("confirmation-msg-title"),
                    text: $(targetBtn).data("confirmation-msg-desc"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: $(targetBtn).data("confirmation-msg-btn-ok"),
                    cancelButtonText: $(targetBtn).data("confirmation-msg-btn-cancel"),
                }).then(function (confirmed) {
                    if (confirmed.value) {
                        ajaxAction(targetBtn);
                    }
                });
            } else {
                ajaxAction(targetBtn);
            }
        });

        _this.$table.on("click", _this.$btnViewRecordHistory, function (e) {
            console.log("ver historial de request");
            console.log($(this));
            _this.loadRequestHistoryModal(
                $(this).data("load-url"),
                "edit",
                $(this).data("title-modal")
            );
        });

        //eventos que se desencadenan al inicializarse el formModal
        _this.$formModal.on("shown.bs.modal", function (evt) {
            //cuando hay mapa en el form modal
            if ($(_this.$mapDiv).length) {
                //console.log("hay mapa");
                //defino region central de mapa
                let $modalBody = $(".modal-body", _this.$formModal);
                let originLat = 22.096667795582714;
                let originLon = -79.80700498766025;
                let zoom = 6;

                // let $mapContainer = $modalBody.find(".map").first();
                //
                // //console.log($mapContainer);
                // let objectCreated = L.DomUtil.get($mapContainer)._leaflet_id;
                // //console.log(objectCreated);
                // //console.log(_this.$mapPlace);
                // if (objectCreated !== undefined && objectCreated !== null) {
                //     console.log("ya estaba");
                //     console.log(originLat);
                //     console.log(originLon);
                //     _this.$mapRoute.setView([originLat, originLon], zoom);
                // } else {
                //     //console.log($mapContainer);
                //     console.log("es nuevo");
                //     console.log(originLat);
                //     console.log(originLon);
                //     //originLat = 22.59278372516801;
                //     //originLon = -83.72542390557545;
                //     //zoom = 10;
                //     _this.$mapRoute = L.map($mapContainer.get(0)).setView([originLat, originLon], zoom);
                // }

                ////////////////////////

                let $mapContainer = $modalBody.find(".map").first();
                console.log($mapContainer);

// Comprobamos si el mapa ya ha sido inicializado
                let mapAlreadyCreated = _this.$mapRoute && _this.$mapRoute._container === $mapContainer.get(0);

                console.log("Map already created:", mapAlreadyCreated);

                if (mapAlreadyCreated) {
                    console.log("ya estaba");
                    console.log(originLat);
                    console.log(originLon);
                    _this.$mapRoute.setView([originLat, originLon], zoom);
                } else {
                    console.log("es nuevo");
                    console.log(originLat);
                    console.log(originLon);

                    // Inicializamos el mapa
                    _this.$mapRoute = L.map($mapContainer.get(0)).setView([originLat, originLon], zoom);
                }

/////////////////////////
                //defino limites (cuba)
                // Definir los límites de la región (ejemplo: latitudes 40 a 45, longitudes -75 a -70)
                var southWest = L.latLng(19.5, -85), //limite mapa cuba abajo-izquierda
                    northEast = L.latLng(23.5, -73.9), //limite mapa de cuba arriba-derecha
                    bounds = L.latLngBounds(southWest, northEast);

                L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                    attribution: "© OpenStreetMap contributors",
                }).addTo(_this.$mapRoute);

                //deshabilita o habilita scroll com mouse
                if ($("#map").hasClass("no-mouse-zoom")) {
                    _this.$mapRoute.scrollWheelZoom.disable();
                }

                // Restringir el mapa a la región definida
                _this.$mapRoute.setMaxBounds(bounds);
                /*map.on("drag", function () {
                  map.panInsideBounds(bounds, { animate: false });
                });
                map.on("dragend", function () {
                  map.panInsideBounds(bounds, { animate: true });
                });*/

                //busco en el formulario, el campo coordinates y cojo su valor para marcar en el mapa
                let coordinatesValue = $(_this.$mapDiv)
                    .closest("#crud-form")
                    .find(".coordinates")
                    .val();
                //console.log(coordinatesValue);
                if (
                    coordinatesValue === null ||
                    coordinatesValue === "" ||
                    coordinatesValue === undefined
                ) {
                } else {
                    let arreglo = coordinatesValue.split(" ");
                    console.log(arreglo);
                    let lat = arreglo[0];
                    let lon = arreglo[1];
                    _this.$mapRoute.setView([lat, lon], 12);

                    _this.$mapRoute.eachLayer(function (layer) {
                        if (layer instanceof L.Marker) {
                            layer.remove();
                        }
                    });

                    //agrefo marcador en punto seleccionado
                    L.marker([lat, lon]).addTo(_this.$mapRoute);
                }

                //para el caso del formulario de rutas                l
                let selectOrigin = $(_this.$mapDiv)
                    .closest("#crud-form")
                    .find(".origin-field");
                let selectDestination = $(_this.$mapDiv)
                    .closest("#crud-form")
                    .find(".destination-field");
                if (selectOrigin.length > 0 && selectDestination.length > 0) {
                    if (selectOrigin.prop("selectedIndex")) {
                        let coordinatesOrigin = [
                            selectOrigin.find(":selected").data("lat").toString(),
                            selectOrigin.find(":selected").data("lon").toString(),
                        ];
                        let coordinatesDestination = [
                            selectDestination.find(":selected").data("lat").toString(),
                            selectDestination.find(":selected").data("lon").toString(),
                        ];

                        var puntoInicio = L.latLng(
                            coordinatesOrigin[0],
                            coordinatesOrigin[1]
                        );
                        var puntoFin = L.latLng(
                            coordinatesDestination[0],
                            coordinatesDestination[1]
                        );

                        // Crear un control de rutas
                        var controlRutas = L.Routing.control({
                            waypoints: [puntoInicio, puntoFin],
                            language: "es",
                            routeWhileDragging: true,
                        }).addTo(_this.$mapRoute);

                        controlRutas.on("routesfound", function (event) {
                            var ruta = event.routes[0]; // Tomar la primera ruta encontrada
                            var distancia = ruta.summary.totalDistance; // Distancia en metros
                            var demora = ruta.summary.totalTime; // Demora en segundos

                            //estos seran los valores reales
                            $("#ct_route_estimatedDistance").val(distancia);
                            $("#ct_route_estimatedTime").val(demora);
                            //ahora lo que muestro
                            $("#ct_route_estimatedDistance_view").val(
                                Math.round(distancia / 1000).toFixed(1) + " Km"
                            );
                            $("#ct_route_estimatedTime_view").val(
                                _this.convertirSegundosAHorasYMinutos(demora)
                            );

                            console.log("Distancia:", distancia, "metros");
                            console.log("Demora:", demora, "segundos");
                        });
                    }
                }
                //para el caso del formulario de privado
                //console.log($($(this).find('form')[0]).attr('name'));
                // if ($($(this).find("form")[0]).attr("name") == "private_service") {
                //  console.log('mando a ejecutar funcion');
                _this.routing(_this, false);
                // }

                _this.$mapRoute.on("click", function (e) {
                    if (!$(_this.$mapDiv).hasClass("no-clickable")) {
                        // Obtener las coordenadas del clic
                        var lat = e.latlng.lat;
                        var lon = e.latlng.lng;

                        // Hacer algo con las coordenadas (por ejemplo, mostrarlas en la consola)
                        console.log("Coordenadas del clic:", lat, lon);

                        //limpio mapa para volver a marcar
                        _this.$mapRoute.eachLayer(function (layer) {
                            if (layer instanceof L.Marker) {
                                layer.remove();
                            }
                        });

                        //agrefo marcador en punto seleccionado
                        L.marker([lat, lon]).addTo(_this.$mapRoute);

                        /*
                                L.circle([51.508, -0.11], {
                                    color: 'red',
                                    fillColor: '#f03',
                                    fillOpacity: 0.5,
                                    radius: 500
                                }).addTo(map)
                                */

                        //.bindPopup('A pretty CSS popup.<br> Easily customizable.')
                        //.openPopup();

                        //seteo el valor
                        $(_this.$mapDiv)
                            .closest("#crud-form")
                            .find(".coordinates")
                            .val(lat.toString() + " " + lon.toString());

                        console.log(
                            $(_this.$mapDiv).closest("#crud-form").find(".coordinates").val()
                        );
                    }
                });
            }
        });

        _this.$formModal.on("change", _this.$select2RequestOrigin, function (evt) {
            if ($("#pickupLocations-list").find(".collection-item").length > 0) {
                Swal.fire({
                    title: $(evt.target).data("confirmation-msg-title"),
                    text: $(evt.target).data("confirmation-msg-desc"),
                    icon: "warning",
                    showCancelButton: false,
                    confirmButtonText: $(evt.target).data("confirmation-msg-btn-ok"),
                }).then(function (confirmed) {
                    if (confirmed.value) {
                        _this.clearCollection($("#pickupLocations-list"));
                    }
                });
            }
        });

        _this.$formModal.on("change", _this.$select2RequestRoute, function (evt) {
            //dibujo en el mapa
            _this.routing(_this, true);

            ///limpio los lugares ya establecidos
            if (
                $("#pickupLocations-list").find(".collection-item").length > 0 ||
                $("#arrivalLocations-list").find(".collection-item").length > 0
            ) {
                Swal.fire({
                    title: $(evt.target).data("confirmation-msg-title"),
                    text: $(evt.target).data("confirmation-msg-desc"),
                    icon: "warning",
                    showCancelButton: false,
                    confirmButtonText: $(evt.target).data("confirmation-msg-btn-ok"),
                }).then(function (confirmed) {
                    if (confirmed.value) {
                        _this.clearCollection($("#pickupLocations-list"));
                        _this.clearCollection($("#arrivalLocations-list"));
                    }
                });
            }
        });

        _this.$formModal.on(
            "change",
            _this.$selectSourceServiceRequest,
            function (evt) {
                //console.log($(this).val());
                if ($(this).val() == $(this).data("ext-provider")) {
                    console.log("es tercero");
                    $(_this.$selectExternalSourceServiceRequest).prop("required", true);
                    $(_this.$selectExternalSourceServiceRequest).prop("disabled", false);
                    $(".col-external-provider").css({
                        display: "",
                    });
                } else {
                    console.log("es cliente");
                    $(_this.$selectExternalSourceServiceRequest).prop("required", false);
                    $(_this.$selectExternalSourceServiceRequest).prop("disabled", true);
                    $(".col-external-provider").css({
                        display: "none",
                    });
                }
            }
        );

        _this.$formModal.on(
            "change",
            _this.$selectServiceRequestOwner,
            function (evt) {
                $(".service-request-owner-email").val(
                    $($(this).select2("data")[0].element).data("email")
                );
                $(".service-request-owner-name").val(
                    $($(this).select2("data")[0].element).data("fullname")
                );
                $(".service-request-owner-phone").val(
                    $($(this).select2("data")[0].element).data("phone")
                );
            }
        );

        _this.$personFormModal.on(
            "click",
            _this.$btnSavePersonModal,
            function (evt) {
                let $form = _this.$personFormModal.find("form");
                if ($form.valid()) {
                    //$form.trigger("submit");
                    $.LoadingOverlay("show");
                    $.ajax({
                        url: $($form).attr("action"),
                        type: $($form).attr("method"),
                        data: $($form).serialize(),
                    })
                        .done(function (res) {
                            console.log("guarde, ahora pido el que guarde");
                            console.log($($form).find("#person_dni").val());

                            //todo bien, hago peticion para obtener el nuevo
                            $.ajax({
                                url:
                                    "admin/get-person-by-email/" +
                                    $($form).find("#person_email").val(),
                                type: "GET",
                                /*data: {
                                  dni: $($form).find("#person_dni").val(),
                                },*/
                            })
                                .done(function (res) {
                                    //todo bien, hago peticion para obtener el nuevo
                                    let content = JSON.parse(res.content);
                                    let text =
                                        content.name +
                                        " " +
                                        content.surnames +
                                        // (content.secondsurname != null
                                        //     ? " " + content.secondsurname
                                        //     : "") +
                                        (content.nick != null
                                            ? " " + "(" + content.nick + ")"
                                            : "");
                                    var newOption = new Option(text, content.id, false, true);
                                    $(".service-request-owner")
                                        .append(newOption)
                                        .trigger("change");
                                    //corrijo el tema del spam
                                    $(".service-request-owner-name").val(
                                        content.name +
                                        " " +
                                        content.surnames
                                        // +
                                        // (content.secondsurname != null
                                        //     ? " " + content.secondsurname
                                        //     : "")
                                    );
                                    $(".service-request-owner-email").val(content.email);
                                    $(".service-request-owner-phone").val(content.phone);
                                    _this.$personFormModal.modal("hide");
                                })
                                .fail(function (res) {
                                    Swal.fire({
                                        title: "Oops!!",
                                        text: "Se produjo algún error procesando los datos enviados",
                                        icon: "warning",
                                    });
                                })
                                .always(function () {
                                    $.LoadingOverlay("hide");
                                });
                        })
                        .fail(function (res) {
                            //console.log("fallo, ahora veo el por que");
                            if (res.status == 400) {
                                Swal.fire({
                                    title: "Oops!!",
                                    text: "Existen inconsistencias en los datos enviados, probablemente ya exista registrada una persona con el email insertado, por favor, revise...",
                                    icon: "warning",
                                });
                                console.log(res);
                            }
                        })
                        .always(function () {
                            $.LoadingOverlay("hide");
                        });
                }
            }
        );

        _this.$formModal.on(
            "change",
            _this.$select2RequestDestination,
            function (evt) {
                if ($("#arrivalLocations-list").find(".collection-item").length > 0) {
                    Swal.fire({
                        title: $(evt.target).data("confirmation-msg-title"),
                        text: $(evt.target).data("confirmation-msg-desc"),
                        icon: "warning",
                        showCancelButton: false,
                        confirmButtonText: $(evt.target).data("confirmation-msg-btn-ok"),
                    }).then(function (confirmed) {
                        if (confirmed.value) {
                            _this.clearCollection($("#arrivalLocations-list"));
                        }
                    });
                }
            }
        );
        _this.$formModal.on(
            "show",
            _this.$inputPlacePassengerCuantity,
            function (evt) {
                console.log(this);
            }
        );

        _this.$formModal.on(
            "change",
            _this.$inputPrivateServiceQuantityPersons,
            function (evt) {
                //actualizo maximos en listado de pasajeros a recoger y dejar
                $(this)
                    .closest("form")
                    .find(_this.$inputPlacePassengerCuantity)
                    .each(function (index, input) {
                        console.log(
                            "estoy dentro del each de find cantidad de personaspor place"
                        );
                        console.log(index);
                        $(input).attr(
                            "max",
                            $(input)
                                .closest("form")
                                .find(_this.$inputPrivateServiceQuantityPersons)
                                .first()
                                .val()
                        );
                    });

                //actualizo la ocupacion
                $(_this.$occupiedCapacityText).find("span").first().html($(this).val());

                //cuardo capacidad de vehiculo en variable
                let vehicle_capacity = 0;
                let haveSelection = true;
                $(".vehicle-image-selector")
                    .find('input[type="radio"]')
                    .each(function (index, option) {
                        if (
                            parseInt($(option).data("vehicle-capacity")) <
                            parseInt($(_this.$inputPrivateServiceQuantityPersons).val())
                        ) {
                            //es menor a la necesitada, deshabilito
                            console.log("las deshabilito");
                            if ($(option).prop("checked")) {
                                console.log("estaba checkeada, haveselection a false");
                                haveSelection = false;
                            }
                            _this.enabledVehicleOption(option, false);
                        } else {
                            //es mayor a la necesitada, habilito
                            console.log("la habilito");
                            _this.enabledVehicleOption(option, true);
                            if (!haveSelection) {
                                console.log("haveselection en false, paso a true");
                                $(option).prop("checked", true);
                                haveSelection = true;
                            } else {
                                console.log("haveselection ya estaba en true");
                            }
                        }

                        if ($(option).prop("checked")) {
                            vehicle_capacity = $(option).data("vehicle-capacity");
                            //console.log(el.data("vehicle-capacity"));
                        }
                        console.log(
                            "checked: " +
                            $(option).prop("checked") +
                            "    haveSelection:" +
                            haveSelection
                        );
                    });

                $(_this.$availableCapacityText)
                    .find("span")
                    .first()
                    .html(
                        vehicle_capacity -
                        $(_this.$inputPrivateServiceQuantityPersons).val()
                    );
            }
        );

        _this.$formModal.on(
            "change",
            _this.$selectVehiclePrototype,
            function (evt) {
                console.log("change");
                //console.log(evt.target);
                //console.log(evt.target.disabled);
                if (evt.target.type === "radio") {
                    console.log("click en radio");
                    _this.clearVehicleSelection();
                    console.log($(evt.target));
                    $(evt.target).prop("checked", true);
                    let img = $(evt.target)
                        .closest(".vehicle-image-option")
                        .find("label img")
                        .first();
                    $(img).css("border-color", "#007bff");

                    //cambio texto debajo
                    $(_this.$seatingCapacityText)
                        .find("span")
                        .first()
                        .html($(evt.target).data("vehicle-capacity"));
                    $(_this.$availableCapacityText)
                        .find("span")
                        .first()
                        .html(
                            $(evt.target).data("vehicle-capacity") -
                            $(_this.$inputPrivateServiceQuantityPersons).val()
                        );
                }
            }
        );

        _this.$formModal.on("change", _this.$originSelect, function (evt) {
            _this.routing(_this, true);
        });

        _this.$formModal.on("change", _this.$destinationSelect, function (evt) {
            _this.routing(_this, true);
        });


        // _this.$evaluateRequestModal.on('shown.bs.modal', function () {
        //     $(document).off('focusin.modal');
        // });

        _this.$evaluateRequestModal.on("click", _this.$btnRejectRequest, function (evt) {
            let $form = _this.$evaluateRequestModal.find("form");
            let $note = $form.find("#notes-list textarea");

            function processRequest() {
                //$form.trigger("submit");
                $.LoadingOverlay("show");
                let data = $($form).serializeArray();
                data.push({name: "evaluation", value: "reject"});
                console.log(data);
                $.ajax({
                    url: $($form).attr("action"),
                    type: $($form).attr("method"),
                    data: $.param(data),
                })
                    .done(function (res) {
                        if (res.actionValid) {
                            if (res.msg) {
                                Swal.fire({
                                    title: res.title,
                                    html: res.msg,
                                    icon: "info",
                                });
                            }
                            _this.$evaluateRequestModal.modal("hide");
                            _this.$table.DataTable().ajax.reload();
                        } else {
                            Swal.fire({
                                title: res.title,
                                html: res.msg,
                                icon: "warning",
                            });
                        }
                    })
                    .fail(function (res) {
                        console.log("fail");
                        Swal.fire({
                            title: "Oops!!",
                            text: "Ha ocurrido un error",//JSON.parse(res.content)["msg"],
                            icon: "error",
                        });
                    })
                    .always(function () {
                        $.LoadingOverlay("hide");
                    });
            }

            if ($note.val() == "") {
                async function showSweetAlert(btn, formNote) {
                    const {value: note} = await Swal.fire({
                        icon: "info",
                        title: btn.data("swal-title"),
                        input: btn.data("swal-input-type"),
                        inputLabel: btn.data("swal-input-label"),
                        inputPlaceholder: btn.data("swal-input-placeholder"),
                        inputAttributes: {
                            "aria-label": "Type your message here"
                        },
                        showCancelButton: true,
                        confirmButtonText: 'Rechazar',
                        cancelButtonText: 'Cancelar',
                        inputValidator: (value) => {
                            if (!value) {
                                return btn.data("swal-input-error-empty"); // Mensaje de error si el campo está vacío
                            }
                        }
                    });
                    if (note) {
                        formNote.val(note);
                        processRequest();
                    }
                }

                $(document).off('focusin.modal'); ///para que le quite el foco al modal
                let btn = $(this);
                showSweetAlert(btn, $note);
                $(document).on('focusin.modal'); ///para que le quite el foco al modal
            } else {
                processRequest();
            }
        });

        _this.$evaluateRequestModal.on("click", _this.$btnAproveRequest, function (evt) {
            let $form = _this.$evaluateRequestModal.find("form");

            function processRequest() {
                //$form.trigger("submit");
                $.LoadingOverlay("show");
                let data = $($form).serializeArray();
                data.push({name: "evaluation", value: "approve"});
                console.log(data);
                console.log("aqui");
                console.log($($form).serializeArray());
                console.log("#####");
                console.log($($form));
                console.log($.param(data));
                $.ajax({
                    url: $($form).attr("action"),
                    type: $($form).attr("method"),
                    data: $.param(data),
                })
                    .done(function (res) {
                        if (res.actionValid) {
                            if (res.msg) {
                                Swal.fire({
                                    title: res.title,
                                    html: res.msg,
                                    icon: "info",
                                });
                            }
                            _this.$evaluateRequestModal.modal("hide");
                            _this.$table.DataTable().ajax.reload();
                        } else {
                            Swal.fire({
                                title: res.title,
                                html: res.msg,
                                icon: "warning",
                            });
                        }
                    })
                    .fail(function (res) {
                        console.log("fail");
                        Swal.fire({
                            title: "Oops!!",
                            text: "Ha ocurrido un error",//JSON.parse(res.content)["msg"],
                            icon: "error",
                        });
                    })
                    .always(function () {
                        $.LoadingOverlay("hide");
                    });
            }

            if ($form.valid()) {
                processRequest();
            }
        });

        _this.$evaluateRequestModal.on("change", _this.$selectRequestPlaceProvince, function (evt) {
            const optionSelectedProvince = $(this).find(':selected');
            const $formSection = $(this).closest('.collection-item');
            $formSection.find(_this.$selectRequestPlaceMunicipality).val('');
            $formSection.find(_this.$selectRequestPlaceMunicipality).find('option:first').prop('selected', true);
            $formSection.find(_this.$selectRequestPlaceMunicipality).find('option').each(function (index, option) {
                if ($(option).data("province-id") === $(optionSelectedProvince).data("id")) {
                    $(option).show();
                } else {
                    $(option).hide();
                }
            });
            $formSection.find(_this.$selectRequestPlaceLocation).val('');
            $formSection.find(_this.$selectRequestPlaceLocation).find('option:first').prop('selected', true);
            $formSection.find(_this.$selectRequestPlaceLocation).find('option').each(function (index, option) {
                if ($(option).data("province-id") === $(optionSelectedProvince).data("id")) {
                    $(option).show();
                } else {
                    $(option).hide();
                }
            });
        });

        _this.$evaluateRequestModal.on("change", _this.$selectRequestPlaceMunicipality, function (evt) {
            const optionSelectedMunicipality = $(this).find(':selected');
            const $formSection = $(this).closest('.collection-item');
            const provinceId = optionSelectedMunicipality.data("province-id");
            $formSection.find(_this.$selectRequestPlaceProvince).val('');
            $formSection.find(_this.$selectRequestPlaceProvince).find('option:first').prop('selected', true);
            $formSection.find(_this.$selectRequestPlaceProvince).find('option').each(function (index, option) {
                if ($(option).data("id") === provinceId) {
                    $(option).prop('selected', true);
                }
            });
            $formSection.find(_this.$selectRequestPlaceLocation).val('');
            $formSection.find(_this.$selectRequestPlaceLocation).find('option:first').prop('selected', true);
            $formSection.find(_this.$selectRequestPlaceLocation).find('option').each(function (index, option) {
                if ($(option).data("municipality") === $(optionSelectedMunicipality).data("id")) {
                    $(option).show();
                } else {
                    $(option).hide();
                }
            });
        });

        _this.$evaluateRequestModal.on("change", _this.$selectRequestPlaceLocation, function (evt) {
            const optionSelectedLocation = $(this).find(':selected');
            const $formSection = $(this).closest('.collection-item');
            console.log($formSection);
            const provinceId = optionSelectedLocation.data("province-id");
            $formSection.find(_this.$selectRequestPlaceProvince).val('');
            $formSection.find(_this.$selectRequestPlaceProvince).find('option:first').prop('selected', true);
            $formSection.find(_this.$selectRequestPlaceProvince).find('option').each(function (index, option) {
                if ($(option).data("id") === provinceId) {
                    $(option).prop('selected', true);
                }
            });
            $formSection.find(_this.$selectRequestPlaceMunicipality).val('');
            $formSection.find(_this.$selectRequestPlaceMunicipality).find('option:first').prop('selected', true);
            $formSection.find(_this.$selectRequestPlaceMunicipality).find('option').each(function (index, option) {
                if ($(option).data("province-id") === provinceId) {
                    $(option).prop('selected', true);
                }
            });
        });
        _this.$evaluateRequestModal.on("click", _this.$btnAddressMap, function (e) {
            let placetype = null;
            console.log($(this));
            if (
                $(this).closest(".collection-container").find("#pickupLocations-list")
                    .length > 0
            ) {
                placetype = "pickup-place";
            } else if (
                $(this).closest(".collection-container").find("#arrivalLocations-list")
                    .length > 0
            ) {
                placetype = "arrival-place";
            } else {
                console.log("bum");
            }
            let viewMapFieldId = null;
            if ($(this).closest(".collection-item").find(_this.$selectRequestPlaceLocation).length > 0) {
                console.log('toca field location');
                viewMapFieldId = $(this).closest(".collection-item").find(_this.$selectRequestPlaceLocation).first().attr("id");
            } else {
                if ($(this).closest(".collection-item").find(_this.$selectRequestPlaceMunicipality).length > 0) {
                    console.log('toca field municipality');
                    viewMapFieldId = $(this).closest(".collection-item").find(_this.$selectRequestPlaceMunicipality).first().attr("id");
                } else {
                    if ($(this).closest(".collection-item").find(_this.$selectRequestPlaceProvince).length > 0) {
                        console.log('toca field province');
                        viewMapFieldId = $(this).closest(".collection-item").find(_this.$selectRequestPlaceProvince).first().attr("id");
                    } else {
                        console.log("hay algun problema, no se donde centrar");
                    }
                }
            }
            _this.loadMapModal(
                placetype,
                $(this)
                    .closest(".collection-item")
                    .find(_this.$inputPlaceCoordinates)
                    .first()
                    .attr("id"),
                $(this)
                    .closest(".collection-item")
                    .find(_this.$inputPlaceCoordinates)
                    .first()
                    .val(),
                viewMapFieldId
            );
        });

        _this.$formModal.on("click", _this.$btnSaveRequestModal, function (evt) {
            let $form = _this.$formModal.find("form");
            if ($form.valid()) {
                //$form.trigger("submit");
                $.LoadingOverlay("show");
                $.ajax({
                    url: $($form).attr("action"),
                    type: $($form).attr("method"),
                    data: $($form).serialize(),
                })
                    .done(function (res) {
                        //console.log(res.data);
                        //console.log(res.actionValid);
                        //console.log(res.msg);
                        if (res.actionValid) {
                            if (res.msg) {
                                Swal.fire({
                                    title: res.title,
                                    html: res.msg,
                                    icon: "info",
                                });
                            }
                            _this.$formModal.modal("hide");
                            _this.$table.DataTable().ajax.reload();
                        } else {
                            Swal.fire({
                                title: res.title,
                                html: res.msg,
                                icon: "warning",
                            });
                        }
                    })
                    .fail(function (res) {
                        console.log("fail");
                        Swal.fire({
                            title: "Oops!!",
                            text: "Ha ocurrido un error",//JSON.parse(res.content)["msg"],
                            icon: "error",
                        });
                    })
                    .always(function () {
                        $.LoadingOverlay("hide");
                    });
            }
        });

        _this.$formModal.on("click", _this.$btnCalculatePrice, function (evt) {
            let $form = _this.$formModal.find("form");
            console.log("calcula");
            if ($form.valid()) {
                console.log("form valido");
                //$form.trigger("submit");
                $.LoadingOverlay("show");
                $.ajax({
                    url: $(_this.$btnCalculatePrice).data("action-url"),
                    type: $($form).attr("method"),
                    data: $($form).serialize(),
                })
                    .done(function (res) {
                        //console.log("done");
                        //console.log(res.prices);
                        $(_this.$inputCalculatedPrice).val(res.prices.netPrice);
                        if ($(_this.$inputToPayPrice).val() === "") {
                            $(_this.$inputToPayPrice).val(res.prices.netPrice);
                        }
                        Swal.fire({
                            title: "Precio Calculado Correctamente",
                            html: "$ " + res.prices.netPrice,
                            icon: "info",
                        });
                    })
                    .fail(function (res) {
                        console.log("fail");
                        console.log(res);
                        Swal.fire({
                            title: res.responseJSON.title,
                            html: res.responseJSON.msg,
                            icon: "warning",
                        });
                    })
                    .always(function () {
                        $.LoadingOverlay("hide");
                    });
            } else {
                Swal.fire({
                    title: "Formulario Invalido",
                    text: "El formulario de la solicitud contiene errores, por favor revise.....",
                    icon: "error",
                });
            }
        });

        _this.$formModal.on("click", _this.$btnSetPriceToPay, function (evt) {
            console.log("seteo pricetopay");
            $(_this.$inputToPayPrice).val($(_this.$inputCalculatedPrice).val());
        });

        //revisaaaaaaaaarrrrrrr

        _this.$formModal.on("click", _this.$btnNewHolderPerson, function (evt) {
            _this.loadPersonFormModal($(this).data("load-url"));
            evt.preventDefault();
            evt.stopPropagation();
        });

        _this.$formModal.on("change", _this.$selectUserRoles, function (evt) {
            if ($(this).val() == "" || $(this).val() == "ROLE_ADMIN") {
                $(_this.$selectUserAgencies)
                    .find("option:selected")
                    .each(function () {
                        console.log(this);
                        $(this).prop("selected", false);
                    });
                $(_this.$selectUserAgencies).trigger("change");
                $(_this.$selectUserAgencies).prop("disabled", true);
                $(_this.$selectUserAgencies).closest(".form-group").addClass("d-none");
            } else {
                $(_this.$selectUserAgencies).prop("disabled", false);
                $(_this.$selectUserAgencies)
                    .closest(".form-group")
                    .removeClass("d-none");
            }
        });

        _this.$checkMultiselectionCrudTable.change(function (evt) {
            let multiselectCheck = this;
            //para que trabaje el del header o el bottom
            _this.$table.find(".crud-multiselection-check").each(function () {
                evt.preventDefault();
                $(this).prop("checked", $(multiselectCheck).prop("checked"));
            });
            //para los elementos de la tabla
            _this.$table.find(".record-selection-check").each(function () {
                $(this).prop("checked", $(multiselectCheck).prop("checked"));
            });
        });
        _this.$formModal.on(
            "change",
            _this.$inputPlacePassengerCuantity,
            function (evt) {
                $(evt.target.closest(".kt-portlet"))
                    .find(".kt-portlet__head-label")
                    .first()
                    .find(".passengers-cuantity-text")
                    .first()
                    .html($(evt.target).val() + " Pasajeros");
            }
        );
        _this.$formModal.on("change", _this.$selectPlaceType, function (evt) {
            _this.updateDataPlace(this);
        });
        _this.$formModal.on("change", _this.$selectPredefinedPlace, function (evt) {
            _this.updateDataPlace(this);
        });
        _this.$formModal.on("change", _this.$selectPlaceLocation, function (evt) {
            console.log(this);
            let cl = ".pickupPlace";
            let address = $(this).closest(cl).find(_this.$inputPlaceAddress).first();
            let coordinates = $(this)
                .closest(cl)
                .find(_this.$inputPlaceCoordinates)
                .first();
            let btnMap = $(this).closest(cl).find(_this.$btnAddressMap).first();
            if ($(this).val() == "") {
                address.val(null);
                coordinates.val(null);
                address.attr("readOnly", true);
                address.removeClass("is-valid is-invalid");
                address.closest(".input-group").find("span.error").first().css({
                    display: "none",
                });
                btnMap.prop("disabled", true);
            } else {
                address.attr("readOnly", false);
                address.removeClass("is-valid is-invalid");
                address.closest(".input-group").find("span.error").first().css({
                    display: "none",
                });
                coordinates.val($(this).data("coordinates"));
                btnMap.prop("disabled", false);
            }
        });
        _this.$formModal.on("shown.bs.modal", function (evt) {
            //aca voy a revisar el tema de limpiar las option del select de predefined place
            //console.log(this);
            let originDestinationDbTb = null;
            let originId = null;
            let destinationId = null;
            let routeField = null;
            let placeType = null;

            function cleanInOrigin(arrayToDelete, option, originDestinationDbTb) {
                if (originDestinationDbTb == "mun") {
                    if ($(option).data("municipality") != originId) {
                        arrayToDelete.push(option);
                    }
                } else if (originDestinationDbTb == "orig-dest") {
                    let found = false;
                    $.each(
                        $(routeField.select2("data")[0].element).data("origin")
                            .originLocations,
                        function () {
                            if (this == $(option).data("location")) {
                                found = true;
                            }
                        }
                    );
                    if (!found) {
                        arrayToDelete.push(option);
                    }
                } else {
                    //error
                }
                return arrayToDelete;
            }

            function cleanInDestination(
                arrayToDelete,
                option,
                originDestinationDbTb
            ) {
                if (originDestinationDbTb == "mun") {
                    if ($(option).data("municipality") != destinationId) {
                        arrayToDelete.push(option);
                    }
                } else if (originDestinationDbTb == "orig-dest") {
                    let found = false;
                    $.each(
                        $(routeField.select2("data")[0].element).data("destination")
                            .destinationLocations,
                        function () {
                            if (this == $(option).data("location")) {
                                found = true;
                            }
                        }
                    );
                    if (!found) {
                        arrayToDelete.push(option);
                    }
                } else {
                    //error
                }
                return arrayToDelete;
            }

            if ($(this).find(_this.$select2RequestRoute).first().length > 0) {
                //es el caso del formulario de request compartida o priv economica
                //console.log("compartida o privada economica");
                originDestinationDbTb = "orig-dest";
                routeField = $(this).find(_this.$select2RequestRoute).first();
                originId = $($(routeField).select2("data")[0].element).data(
                    "origin"
                ).originId;
                destinationId = $($(routeField).select2("data")[0].element).data(
                    "destination"
                ).destinationId;
            } else {
                //no tiene campo ruta
                originDestinationDbTb = "mun";
                /*originField = $(this).find(_this.$select2RequestOrigin).first();
                destinationField = $(this)
                  .find(_this.$select2RequestDestination)
                  .first();*/
                if (
                    $(this).find(_this.$select2RequestOrigin).first().length > 0 &&
                    $(this).find(_this.$select2RequestDestination).first().length > 0
                ) {
                    //es el caso de un formulario de privada
                    //console.log("privada");
                    originId = $(
                        $(this).find(_this.$select2RequestOrigin).first()
                    ).select2("data")[0].id;
                    destinationId = $(
                        $(this).find(_this.$select2RequestDestination).first()
                    ).select2("data")[0].id;
                }
            }
            $(this)
                .find(_this.$selectPredefinedPlace)
                .each(function (index, selectObject) {
                    if ($(selectObject).hasClass("pickup-place-type")) {
                        //console.log("un lugar de pickup");
                        let opcionesAEliminar = [];
                        let selectPlaceType = $(selectObject)
                            .closest(".collection-item")
                            .find(_this.$selectPlaceType)
                            .first();
                        selectObject.forEach(function (option) {
                            if ($(option).data("placetype") != selectPlaceType.val()) {
                                opcionesAEliminar.push(option);
                            } else {
                                cleanInOrigin(opcionesAEliminar, option, originDestinationDbTb);
                            }
                        });
                        opcionesAEliminar.forEach(function (opcion) {
                            selectObject.removeChild(opcion);
                        });
                    } else if ($(selectObject).hasClass("arrival-place-type")) {
                        //console.log("un lugar de arrival");
                        let opcionesAEliminar = [];
                        let selectPlaceType = $(selectObject)
                            .closest(".collection-item")
                            .find(_this.$selectPlaceType)
                            .first();
                        selectObject.forEach(function (option) {
                            if ($(option).data("placetype") != selectPlaceType.val()) {
                                opcionesAEliminar.push(option);
                            } else {
                                cleanInDestination(
                                    opcionesAEliminar,
                                    option,
                                    originDestinationDbTb
                                );
                            }
                        });
                        opcionesAEliminar.forEach(function (opcion) {
                            selectObject.removeChild(opcion);
                        });
                    } else {
                        console.log("bummmmm");
                    }
                });

            //console.log("carga el select predefined");
            console.log("limpio locations");
            let isRouteSelected = false;
            if ($(this).find(_this.$select2RequestRoute).first().length > 0) {
                isRouteSelected = true;
            }
            console.log("este es el tema de ahora");
            console.log(originId);
            //let opcionesAEliminar = [];
            $(this)
                .find(_this.$selectPlaceLocation)
                .each(function (index, selectObject) {
                    //console.log("un lugar de pickup");
                    let opcionesAEliminar = [];
                    if (isRouteSelected) {
                        //let routeOptionSelected = $(this).find(_this.$select2RequestRoute + " option:selected").first();
                        //let originId = routeOptionSelected.data('origin').originId;
                        selectObject.forEach(function (option) {
                            if ($(option).data("origin-destination") !== originId) {
                                opcionesAEliminar.push(option);
                            }
                            /*else {
                              cleanInOrigin(opcionesAEliminar, option, originDestinationDbTb);
                            }*/
                        });
                        console.log(opcionesAEliminar);
                        opcionesAEliminar.forEach(function (opcion) {
                            selectObject.removeChild(opcion);
                        });
                    }
                });
        });

        _this.$btnRapidEnable.click(function (evt) {
            let btn = $(this);
            let objectiveElements = [];
            _this.$table.find(".record-selection-check").each(function () {
                if ($(this).prop("checked")) {
                    objectiveElements.push($(this).val());
                }
            });
            if (objectiveElements.length == 0) {
                //no hay seleccion
                Swal.fire({
                    title: btn.data("msg-title-attention"),
                    text: btn.data("msg-no-elements-checked"),
                    icon: "error",
                });
            } else {
                //aca hago la pincha
                $.LoadingOverlay("show");
                $.ajax({
                    url: $(btn).data("action-url"),
                    type: "GET",
                    data: {
                        ids: objectiveElements, //JSON. stringify(objectiveElements)
                    },
                })
                    .done(function (res) {
                        //aca todo ok
                        toastr.success(
                            JSON.parse(res.content)["msg"],
                            JSON.parse(res.content)["title"],
                            {
                                timeOut: 3000,
                                //progressBar : true,
                                newestOnTop: true,
                            }
                        );
                        _this.$table.DataTable().ajax.reload();
                        //desmarco seleccion
                        _this.$table.find(".crud-multiselection-check").each(function () {
                            evt.preventDefault();
                            $(this).prop("checked", false);
                        });
                    })
                    .fail(function (res) {
                        Swal.fire({
                            title: "Oops!!",
                            text: JSON.parse(res.content)["msg"],
                            icon: "error",
                        });
                    })
                    .always(function () {
                        $.LoadingOverlay("hide");
                    });
            }
        });

        _this.$btnRapidDisable.click(function (evt) {
            let btn = $(this);
            let objectiveElements = [];
            _this.$table.find(".record-selection-check").each(function () {
                if ($(this).prop("checked")) {
                    objectiveElements.push($(this).val());
                }
            });
            if (objectiveElements.length == 0) {
                //no hay seleccion
                Swal.fire({
                    title: btn.data("msg-title-attention"),
                    text: btn.data("msg-no-elements-checked"),
                    icon: "error",
                });
            } else {
                //aca hago la pincha
                Swal.fire({
                    title: $(btn).data("confirmation-msg-title"),
                    text: $(btn).data("confirmation-msg-desc"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: $(btn).data("confirmation-msg-btn-ok"),
                    cancelButtonText: $(btn).data("confirmation-msg-btn-cancel"),
                }).then(function (confirmed) {
                    if (confirmed.value) {
                        $.LoadingOverlay("show");
                        $.ajax({
                            url: $(btn).data("action-url"),
                            type: "GET",
                            data: {
                                ids: objectiveElements, //JSON. stringify(objectiveElements)
                            },
                        })
                            .done(function (res) {
                                //aca todo ok
                                toastr.success(
                                    JSON.parse(res.content)["msg"],
                                    JSON.parse(res.content)["title"],
                                    {
                                        timeOut: 3000,
                                        //progressBar : true,
                                        newestOnTop: true,
                                    }
                                );
                                _this.$table.DataTable().ajax.reload();
                                //desmarco seleccion
                                _this.$table
                                    .find(".crud-multiselection-check")
                                    .each(function () {
                                        evt.preventDefault();
                                        $(this).prop("checked", false);
                                    });
                            })
                            .fail(function (res) {
                                Swal.fire({
                                    title: "Oops!!",
                                    text: JSON.parse(res.content)["msg"],
                                    icon: "error",
                                });
                            })
                            .always(function () {
                                $.LoadingOverlay("hide");
                            });
                    }
                });
            }
        });

        _this.$mapModal.on("shown.bs.modal", function (evt) {
            console.log("se carga el modal map");
            let $modalBody = $(".modal-body", _this.$mapModal);
            let originLat = 22.096667795582714;
            let originLon = -79.80700498766025;
            let zoom = 13;
            if ($(_this.$mapModal).attr("current-coordinates-field") !== "") {
                let coordinates = $(_this.$mapModal)
                    .attr("current-coordinates-field")
                    .split(" ");
                originLat = coordinates[0];
                originLon = coordinates[1];
            } else {
                if ($(_this.$mapModal).hasClass("pickup-place")) {
                    if ($(_this.$select2RequestOrigin).length > 0) {
                        originLat = $(
                            $(_this.$select2RequestOrigin).select2("data")[0].element
                        ).data("lat");
                        originLon = $(
                            $(_this.$select2RequestOrigin).select2("data")[0].element
                        ).data("lon");
                    } else if ($(_this.$select2RequestRoute).length > 0) {

                        if (
                            $(
                                "#" +
                                $(_this.$mapModal).attr("id-location-field") +
                                " option:selected"
                            ).val() !== "" &&
                            $(
                                "#" +
                                $(_this.$mapModal).attr("id-location-field") +
                                " option:selected"
                            ).val()
                        ) {
                            let coordinates = $(
                                "#" +
                                $(_this.$mapModal).attr("id-location-field") +
                                " option:selected"
                            )
                                .data("coordinates")
                                .split(" ");
                            originLat = coordinates[0];
                            originLon = coordinates[1];
                        } else {
                            originLat = $(
                                $(_this.$select2RequestRoute).select2("data")[0].element
                            )
                                .data("origin")
                                .originCoordinates.split(" ")[0];
                            originLon = $(
                                $(_this.$select2RequestRoute).select2("data")[0].element
                            )
                                .data("origin")
                                .originCoordinates.split(" ")[1];
                        }
                    } else {
                        console.log("hasta ahora nada, en origin");
                        if (
                            $(
                                "#" +
                                $(_this.$mapModal).attr("id-location-field") +
                                " option:selected"
                            ).val() !== "" &&
                            $(
                                "#" +
                                $(_this.$mapModal).attr("id-location-field") +
                                " option:selected"
                            ).val()
                        ) {
                            let coordinates = $(
                                "#" +
                                $(_this.$mapModal).attr("id-location-field") +
                                " option:selected"
                            )
                                .data("coordinates")
                                .split(" ");
                            originLat = coordinates[0];
                            originLon = coordinates[1];
                        } else {
                            console.log("no habia seleccion");
                            originLat = 22.096667795582714;
                            originLon = -79.80700498766025;
                            zoom = 6;
                        }
                    }
                } else if ($(_this.$mapModal).hasClass("arrival-place")) {
                    if ($(_this.$select2RequestDestination).length > 0) {
                        console.log("en if de request destination");
                        originLat = $(
                            $(_this.$select2RequestDestination).select2("data")[0].element
                        ).data("lat");
                        originLon = $(
                            $(_this.$select2RequestDestination).select2("data")[0].element
                        ).data("lon");
                    } else if ($(_this.$select2RequestRoute).length > 0) {
                        console.log("en if de request route");
                        originLat = $(
                            $(_this.$select2RequestRoute).select2("data")[0].element
                        )
                            .data("destination")
                            .destinationCoordinates.split(" ")[0];
                        originLon = $(
                            $(_this.$select2RequestRoute).select2("data")[0].element
                        )
                            .data("destination")
                            .destinationCoordinates.split(" ")[1];
                    } else {
                        //error
                        console.log("hasta ahora nada, en destination");
                        if (
                            $(
                                "#" +
                                $(_this.$mapModal).attr("id-location-field") +
                                " option:selected"
                            ).val() !== "" &&
                            $(
                                "#" +
                                $(_this.$mapModal).attr("id-location-field") +
                                " option:selected"
                            ).val()
                        ) {
                            let coordinates = $(
                                "#" +
                                $(_this.$mapModal).attr("id-location-field") +
                                " option:selected"
                            )
                                .data("coordinates")
                                .split(" ");
                            originLat = coordinates[0];
                            originLon = coordinates[1];
                        } else {
                            console.log("no habia seleccion");
                            originLat = 22.096667795582714;
                            originLon = -79.80700498766025;
                            zoom = 6;
                        }
                    }
                    // $selectRequestPlaceProvince: ".request-place-province",
                    //     $selectRequestPlaceMunicipality: ".request-place-municipality",
                    //     $selectRequestPlaceLocation: ".request-place-location",
                } else {
                    Swal.fire({
                        title: "ERROR!!",
                        text: "Error, tipo de lugar indefinido",
                        icon: "error",
                    });
                }
            }

            console.log($modalBody);
            console.log(_this.$mapSelectPlaceCoordinates);

            let $mapContainer = $modalBody.find(_this.$mapSelectPlaceCoordinates).first();
            console.log($mapContainer);

// Comprobamos si el mapa ya ha sido inicializado
            let mapAlreadyCreated = _this.$mapPlace && _this.$mapPlace._container === $mapContainer.get(0);

            console.log("Map already created:", mapAlreadyCreated);

            if (mapAlreadyCreated) {
                console.log("ya estaba");
                console.log(originLat);
                console.log(originLon);
                console.log(zoom);
                _this.$mapPlace.setView([originLat, originLon], zoom);
            } else {
                console.log("es nuevo");
                console.log(originLat);
                console.log(originLon);
                console.log(zoom);
                // Inicializamos el mapa
                _this.$mapPlace = L.map($mapContainer.get(0)).setView([originLat, originLon], zoom);
            }
            console.log(_this.$mapPlace);
            // console.log("antes de crear");
            // _this.$mapPlace = L.map($mapContainer).setView([22.59278372516801, -83.72542390557545], 13);
            // console.log('despues de crear');

            // Definir los límites de la región (ejemplo: latitudes 40 a 45, longitudes -75 a -70)
            let southWest = L.latLng(19.5, -85); //limite mapa cuba abajo-izquierda
            let northEast = L.latLng(23.5, -73.9); //limite mapa de cuba arriba-derecha
            let bounds = L.latLngBounds(southWest, northEast);

            L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                attribution: "© OpenStreetMap contributors",
            }).addTo(_this.$mapPlace);

            //_this.$mapPlace.invalidateSize();

            // Restringir el mapa a la región definida
            _this.$mapPlace.setMaxBounds(bounds);

            _this.$mapPlace.on("drag", function () {
                _this.$mapPlace.panInsideBounds(bounds, {animate: false});
            });
            _this.$mapPlace.on("dragend", function () {
                _this.$mapPlace.panInsideBounds(bounds, {animate: true});
            });

            //limpio marcadores
            _this.$mapPlace.eachLayer(function (layer) {
                if (layer instanceof L.Marker) {
                    layer.remove();
                }
            });
            //agrego marcador actual
            if ($(_this.$mapModal).attr("current-coordinates-field") !== "") {
                let coordinates = $(_this.$mapModal)
                    .attr("current-coordinates-field")
                    .split(" ");
                L.marker([coordinates[0], coordinates[1]]).addTo(_this.$mapPlace);
            }
            _this.$mapPlace.on("click", function (e) {
                if (!$($mapContainer).hasClass("no-clickable")) {
                    console.log("es clickable, pongo marcador");
                    let lat = e.latlng.lat;
                    let lon = e.latlng.lng;
                    // Hacer algo con las coordenadas (por ejemplo, mostrarlas en la consola)
                    console.log("Coordenadas del clic:", lat, lon);
                    //limpio mapa para volver a marcar
                    _this.$mapPlace.eachLayer(function (layer) {
                        if (layer instanceof L.Marker) {
                            layer.remove();
                        }
                    });
                    //agrefo marcador en punto seleccionado
                    L.marker([lat, lon]).addTo(_this.$mapPlace);
                    //seteo el valor

                    console.log("en el campo->");
                    console.log($("#" + $(_this.$mapModal).attr("id-coordinates-field")));

                    console.log("seteo el valor->");
                    console.log(lat.toString() + " " + lon.toString());

                    $("#" + $(_this.$mapModal).attr("id-coordinates-field")).val(
                        lat.toString() + " " + lon.toString()
                    );
                    console.log("ahora tiene el valor->");
                    console.log($("#" + $(_this.$mapModal).attr("id-coordinates-field")).val());
                }
            });
        });
        //cuando se oculta el mapModal
        _this.$mapModal.on("hidden.bs.modal", function (evt) {
            $(_this.$formModal).removeClass("modal-fade");///si estaba desde un formmodal lo vuelvo a mostrar
            $(_this.$evaluateRequestModal).removeClass("modal-fade"); //si estaba en un evaluate lo vuelvo a mostrar
            $(_this.$mapModal).removeClass("pickup-place");
            $(_this.$mapModal).removeClass("arrival-place");
        });
        _this.$personFormModal.on("hidden.bs.modal", function (evt) {
            $(_this.$formModal).removeClass("modal-fade");
        });
    },

    loadRequestHistoryModal: function (url, type, titleModal, silent) {
        console.log(url);
        let _this = this;
        // _this.toggleBodyBlock();
        let $modalBody = $(".modal-body", _this.$historyViewerModal);
        if (titleModal != null) {
            _this.$historyViewerModal.find(".modal-title").text(titleModal);
        }
        if (silent !== true) {
            $.LoadingOverlay("show");
        } else {
            //$modalBody.html(_this.loadPlaceholder);
        }
        $modalBody.load(url, function (res) {
            App.initComponents($modalBody);
            _this.$historyViewerModal.trigger("api_form_modal_loaded", {
                type: type,
            });
            if (silent !== true) {
                $.LoadingOverlay("hide");
                _this.$historyViewerModal.modal("show");
            }
        });
    },

    loadEvaluateRequestModal: function (url, type, titleModal, silent) {
        console.log(url);
        let _this = this;
        // _this.toggleBodyBlock();
        let $modalBody = $(".modal-body", _this.$evaluateRequestModal);
        if (titleModal != null) {
            _this.$evaluateRequestModal.find(".modal-title .title-text").text(titleModal);
        }
        if (silent !== true) {
            $.LoadingOverlay("show");
        } else {
            //$modalBody.html(_this.loadPlaceholder);
        }
        $modalBody.load(url, function (res) {
            App.initComponents($modalBody);
            _this.$evaluateRequestModal.trigger("api_form_modal_loaded", {
                type: type,
            });
            if (silent !== true) {
                $.LoadingOverlay("hide");
                _this.$evaluateRequestModal.modal("show");
            }
        });
    },

    convertirSegundosAHorasYMinutos: function (segundos) {
        var horas = Math.floor(segundos / 3600);
        var minutos = Math.floor((segundos % 3600) / 60);

        // Formatear la salida para tener siempre dos dígitos para minutos
        if (minutos < 10) {
            minutos = "0" + minutos;
        }

        return horas + "h " + minutos + "min";
    },

    routing: function (_this, reset = false) {
        console.log("routing");
        _this.$mapRoute.eachLayer(function (layer) {
            if (layer instanceof L.Marker || layer instanceof L.Routing.Line) {
                layer.remove();
                //map.removeLayer(layer);
            }
        });
        //console.log("funcion routing");
        let selectOrigin = $(_this.$mapDiv).closest("form").find(".origin-field");
        let selectDestination = $(_this.$mapDiv)
            .closest("form")
            .find(".destination-field");
        let waypointsField = $(_this.$mapDiv)
            .closest("form")
            .find(".waypoints-field");

        let selectRoute = $(_this.$mapDiv)
            .closest("form")
            .find(".resquest-route-field");
        let showRoute = false;
        let waypointsArray = [];
        if (selectOrigin.length > 0 && selectDestination.length > 0) {
            //console.log("primer if");
            if (selectOrigin.prop("selectedIndex")) {
                //console.log("segundo if");
                let coordinatesOrigin = [
                    selectOrigin.find(":selected").data("lat").toString(),
                    selectOrigin.find(":selected").data("lon").toString(),
                ];
                let coordinatesDestination = [
                    selectDestination.find(":selected").data("lat").toString(),
                    selectDestination.find(":selected").data("lon").toString(),
                ];
                let puntoInicio = L.latLng(coordinatesOrigin[0], coordinatesOrigin[1]);
                let puntoFin = L.latLng(
                    coordinatesDestination[0],
                    coordinatesDestination[1]
                );

                waypointsArray.push(puntoInicio);
                //los puntos de encuentro
                if (reset) {
                    console.log("resetea");
                    $(_this.$meetingPointList + " select").each(function (index, select) {
                        let indiceSeleccionado = select.selectedIndex;
                        let optionSeleccionado = $(select.options[indiceSeleccionado]);
                        let coordinatesMeetingPoint = [
                            optionSeleccionado.data("lat").toString(),
                            optionSeleccionado.data("lon").toString(),
                        ];
                        let puntoIntermedio = L.latLng(
                            coordinatesMeetingPoint[0],
                            coordinatesMeetingPoint[1]
                        );
                        waypointsArray.push(puntoIntermedio);
                    });
                } else {
                    //console.log("no resetea");
                    //console.log(waypointsField.val());
                    /*
                    if ( waypointsField.val() !== "") {
                      let tempArray = waypointsField.val().split(",");
                      tempArray.forEach(function (element, index) {
                        //console.log(index);
                        //if (index != 0 && index != tempArray.length - 1) {
                        let cord = element.split(" ");
                        waypointsArray.push(L.latLng(cord[0], cord[1]));
                        //console.log("add point");
                        //}
                      });
                    }
                    */
                }
                waypointsArray.push(puntoFin);

                showRoute = true;
            }
        } else if (selectRoute.length > 0) {
            if (selectRoute.prop("selectedIndex")) {
                //let tmpOrigin
                let coordinatesOrigin = selectRoute
                    .find(":selected")
                    .data("origin")
                    .originCoordinates.toString()
                    .split(" ");
                let coordinatesDestination = selectRoute
                    .find(":selected")
                    .data("destination")
                    .destinationCoordinates.toString()
                    .split(" ");
                let puntoInicio = L.latLng(coordinatesOrigin[0], coordinatesOrigin[1]);
                let puntoFin = L.latLng(
                    coordinatesDestination[0],
                    coordinatesDestination[1]
                );
                waypointsArray.push(puntoInicio);
                //los puntos de encuentro
                let meetingPoints = selectRoute.find(":selected").data("waypoints");
                console.log(meetingPoints);
                if (meetingPoints) {
                    $.each(meetingPoints, function (key, coord) {
                        let tmpCoord = coord.split(" ");
                        let puntoIntermedio = L.latLng(tmpCoord[0], tmpCoord[1]);
                        waypointsArray.push(puntoIntermedio);
                    });
                }
                waypointsArray.push(puntoFin);
                showRoute = true;
            }
        }
        if (showRoute) {
            $.LoadingOverlay("show");
            // Crear un control de rutas
            var controlRutas = L.Routing.control({
                waypoints: waypointsArray,
                language: "es",
                routeWhileDragging: true,
            }).addTo(_this.$mapRoute);
            //console.log(map);
            //console.log(controlRutas);
            controlRutas.on("routesfound", function (event) {
                var ruta = event.routes[0]; // Tomar la primera ruta encontrada
                var distancia = ruta.summary.totalDistance; // Distancia en metros
                var demora = ruta.summary.totalTime; // Demora en segundos

                //console.log(ruta.waypoints);
                let waypointsToSave = "";
                ruta.waypoints.forEach(function (waypoint, index) {
                    //console.log("Waypoint", index + 1, ":", waypoint.latLng);
                    if (index != 0 && index != ruta.waypoints.length - 1) {
                        if (waypointsToSave == "") {
                            waypointsToSave = waypoint.latLng.lat + " " + waypoint.latLng.lng;
                        } else {
                            waypointsToSave =
                                waypointsToSave +
                                "," +
                                waypoint.latLng.lat +
                                " " +
                                waypoint.latLng.lng;
                        }
                    }
                });
                waypointsField.val(waypointsToSave);
                //console.log(waypointsToSave);

                //estos seran los valores reales
                $(".estimated-distance-field").val(distancia);
                $(".estimated-time-field").val(demora);
                //ahora lo que muestro
                $(".estimatedDistance_view").val(
                    Math.round(distancia / 1000).toFixed(1) + " Km"
                );
                $(".estimatedTime_view").val(
                    _this.convertirSegundosAHorasYMinutos(demora)
                );

                //console.log("Distancia:", distancia, "metros");
                //console.log("Demora:", demora, "segundos");
            });

            controlRutas.on("routeselected", function (e) {
                //console.log("Se ha seleccionado una ruta");
                //console.log("La ruta seleccionada es:", e.route);
            });
            $.LoadingOverlay("hide");
        }
    },

    clearVehicleSelection: function () {
        console.log("limpiando seleccion");
        $(this.$vehicleImageSelector)
            .find(".vehicle-image-option")
            .each(function (index, option) {
                let radio = $(option).find('input[type="radio"]').first();
                let img = $(option).find("img").first();
                radio.prop("checked", false);
                img.css("border-color", "transparent");
            });
    },

    clearCollection: function ($collection) {
        $collection.find(".collection-item").each(function (index, element) {
            $(element).addClass("d-none").remove();
        });
    },
    updateDataPlace: function (select) {
        console.log(select);
        let cl = null;
        let loc = null;
        let dbTableLoc = null;
        if ($(select).hasClass("pickup-place-type")) {
            cl = ".pickupPlace";
            if ($(this.$originSelect).length > 0) {
                loc = $(this.$originSelect).val();
                dbTableLoc = "mun";
            } else if ($(this.$select2RequestRoute).length > 0) {
                loc = $($(this.$select2RequestRoute).select2("data")[0].element).data(
                    "origin"
                ).originId;
                dbTableLoc = "orig-dest";
            } else {
                //error
            }
        } else if ($(select).hasClass("arrival-place-type")) {
            cl = ".arrivalPlace";
            if ($(this.$destinationSelect).length > 0) {
                //console.log('es el municipio');
                loc = $(this.$destinationSelect).val();
                dbTableLoc = "mun";
            } else if ($(this.$select2RequestRoute).length > 0) {
                loc = $($(this.$select2RequestRoute).select2("data")[0].element).data(
                    "destination"
                ).destinationId;
                dbTableLoc = "orig-dest";
            } else {
                //error
            }
        }
        let coordinates = $(select)
            .closest(cl)
            .find(this.$inputPlaceCoordinates)
            .first();
        let address = $(select).closest(cl).find(this.$inputPlaceAddress).first();
        let location = $(select)
            .closest(cl)
            .find(this.$selectPlaceLocation)
            .first();
        let btnMap = $(select).closest(cl).find(this.$btnAddressMap).first();

        let selectPlaceType = $(select)
            .closest(cl)
            .find(this.$selectPlaceType)
            .first();
        //let selectPlaceTypeSelectedOption = selectPlaceType.find("option:selected");

        let selectPredefinedPlace = $(select)
            .closest(cl)
            .find(this.$selectPredefinedPlace)
            .first();
        /*
          let selectPredefinedPlaceOption = selectPredefinedPlace
          .find("option:selected")
          .first();
          */
        console.log("quien es location");
        console.log(location);

        if (selectPlaceType.val() == "") {
            console.log("quite seleccion de tipo");
            selectPredefinedPlace.val("");
            selectPredefinedPlace.attr("disabled", true);
            selectPredefinedPlace.removeClass("is-valid is-invalid");
            selectPredefinedPlace
                .closest(".input-group")
                .find("span.error")
                .first()
                .css({
                    display: "none",
                });

            let requestUrl =
                "app/get-locations-by-origindestination-or-municipality/" +
                dbTableLoc +
                "/" +
                loc;

            $.ajax({
                url: requestUrl,
                type: "GET",
                /*data: {
                    dni: $($form).find("#person_dni").val(),
                  },*/
            })
                .done(function (res) {
                    location.empty(); //limpio
                    location.removeClass("readonly-select");
                    location.removeClass("is-valid is-invalid");
                    location.closest(".input-group").find("span.error").first().css({
                        display: "none",
                    });
                    let content = JSON.parse(res.content);
                    if ($.isEmptyObject(content)) {
                        console.log("no trae content");
                        location.val("");
                        //selectPredefinedPlace.attr("disabled", true);
                        address.val(null);
                        address.attr("readOnly", true);
                        location.val("");
                        //location.attr("disabled", true);
                        location.addClass("readonly-select");

                        coordinates.val(null);
                    } else {
                        content.forEach(function (option) {
                            console.log(option);
                            location.append(
                                $("<option>", {
                                    value: option.id,
                                    text: option.name,
                                    "data-id": option.id,
                                    "data-name": option.name,
                                    "data-coordinates": option.coordinates,
                                })
                            );
                        });
                        address.val(null);
                        //estos son con el tema de que desbloqueee la seleccion del location solamente
                        address.attr("readOnly", false);
                        address.removeClass("is-valid is-invalid");
                        address.closest(".input-group").find("span.error").first().css({
                            display: "none",
                        });
                        btnMap.prop("disabled", false);
                        coordinates.val(null);
                    }
                })
                .fail(function (res) {
                    alert("error pidiendo");
                })
                .always(function () {
                    $.LoadingOverlay("hide");
                });
        } else {
            console.log("seleccion del tipo");
            btnMap.prop("disabled", true);
            address.val(null);
            address.attr("readOnly", true);

            location.val("");
            //location.attr("disabled", true);
            location.addClass("readonly-select");

            coordinates.val(null);
            //console.log($(select).attr("id"));
            //console.log(selectPlaceType.attr("id"));
            if ($(select).attr("id") == selectPlaceType.attr("id")) {
                console.log("Seleccionado NUEVO TIPO");
                let requestUrl = null;
                if (dbTableLoc == "mun") {
                    requestUrl =
                        "app/get-predefined-places-by-type-and-municipality/" +
                        selectPlaceType.val() +
                        "/" +
                        loc;
                } else if ((dbTableLoc = "orig-dest")) {
                    requestUrl =
                        "app/get-predefined-places-by-type-and-origindestination/" +
                        selectPlaceType.val() +
                        "/" +
                        loc;
                } else {
                    //error
                }
                $.ajax({
                    url: requestUrl,
                    type: "GET",
                    /*data: {
                      dni: $($form).find("#person_dni").val(),
                    },*/
                })
                    .done(function (res) {
                        selectPredefinedPlace.empty(); //limpio
                        selectPredefinedPlace.removeClass("is-valid is-invalid");
                        selectPredefinedPlace
                            .closest(".input-group")
                            .find("span.error")
                            .first()
                            .css({
                                display: "none",
                            });
                        let content = JSON.parse(res.content);
                        if ($.isEmptyObject(content)) {
                            console.log("no trae content");
                            selectPredefinedPlace.val("");
                            //selectPredefinedPlace.attr("disabled", true);
                            address.val(null);
                            address.attr("readOnly", true);
                            location.val("");
                            //location.attr("disabled", true);
                            location.addClass("readonly-select");

                            coordinates.val(null);
                        } else {
                            content.forEach(function (option) {
                                //console.log(option);
                                selectPredefinedPlace.append(
                                    $("<option>", {
                                        value: option.id,
                                        text: option.name,
                                        "data-id": option.id,
                                        "data-name": option.name,
                                        "data-coordinates": option.coordinates,
                                        "data-address": option.address,
                                        "data-location": option.location,
                                        "data-placeType": option.placeType,
                                    })
                                );
                            });
                        }

                        let selectPredefinedPlaceOption = selectPredefinedPlace
                            .find("option:selected")
                            .first();
                        if (!$.isEmptyObject(content)) {
                            coordinates.val($(selectPredefinedPlaceOption).data("coordinates"));
                            address.val($(selectPredefinedPlaceOption).data("address"));
                            address.attr("readOnly", true);
                            address.removeClass("is-valid is-invalid");
                            address.closest(".input-group").find("span.error").first().css({
                                display: "none",
                            });
                            location.val($(selectPredefinedPlaceOption).data("location"));
                            console.log("en el primero");
                            console.log(location.val());
                            //location.attr("disabled", true);
                            location.addClass("readonly-select");
                            location.removeClass("is-valid is-invalid");
                            location.closest(".input-group").find("span.error").first().css({
                                display: "none",
                            });
                        } else {
                            selectPredefinedPlace.val("");
                            //selectPredefinedPlace.attr("disabled", true);
                            selectPredefinedPlace.attr("required", true);
                            address.val(null);
                            address.attr("readOnly", true);
                            location.val("");
                            //location.attr("disabled", true);
                            location.addClass("readonly-select");
                            coordinates.val(null);
                        }
                        console.log(selectPredefinedPlaceOption);
                    })
                    .fail(function (res) {
                        alert("error pidiendo");
                    })
                    .always(function () {
                        $.LoadingOverlay("hide");
                    });
                selectPredefinedPlace.attr("disabled", false);
            } else {
                console.log("aca click en el segundo");
                let selectedOption = $(select).find("option:selected").first();
                console.log(selectedOption);
                address.val(selectedOption.data("address"));
                address.attr("readOnly", true);

                console.log(parseInt(selectedOption.data("location")));
                location.val(parseInt(selectedOption.data("location")));
                location.addClass("readonly-select");

                coordinates.val(selectedOption.data("location"));

                console.log(location);
                console.log(location.val());
            }
        }
    },
    enabledVehicleOption: function (option, enabled) {
        if (enabled) {
            $(option).prop("checked", false); //siempre desmarco antes de habilitar, aunque logicamente ya deba estars desmarcado
            $(option).prop("disabled", false);
            //restauro las caracteristicas visuales de un elemento habilitado
            $(option).closest(".vehicle-image-option").find("img").first().css({
                opacity: "",
                filter: "",
                "pointer-events": "auto",
            });
        } else {
            $(option).prop("checked", false); //cuando boy a desgabilitar, logicamente lo desmarco
            $(option).prop("disabled", true);
            //estas son las caracteristicas visuales del deshabilitado
            $(option).closest(".vehicle-image-option").find("img").first().css({
                opacity: "0.1",
                filter: "grayscale(100%)",
                "pointer-events": "none",
            });
        }
    },

    loadPersonFormModal: function (url, type, silent) {
        let _this = this;
        $(_this.$formModal).addClass("modal-fade");
        // _this.toggleBodyBlock();
        let $modalBody = $(".modal-body", _this.$personFormModal);
        if (silent !== true) {
            $.LoadingOverlay("show");
        } else {
            //$modalBody.html(_this.loadPlaceholder);
        }
        $modalBody.load(url, function (res) {
            App.initComponents($modalBody);
            _this.$personFormModal.trigger("api_form_modal_loaded", {type: type});
            if (silent !== true) {
                $.LoadingOverlay("hide");
                _this.$personFormModal.modal("show");
            }
        });
    },
};

(function () {
    let $scopeApp = $(".scope-app");
    if ($scopeApp.length) {
        new AppModule($scopeApp).init();
    }
})(jQuery, window, document);
