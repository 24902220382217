import Tree from "@widgetjs/tree";

//notificaciones con toastr
import * as Toastr from "toastr";

//mapas
import L from "leaflet";
require("/node_modules/leaflet/dist/leaflet.css");

// Importa Leaflet Routing Machine y sus estilos
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine";

//notificaciones con PNotify, pero hay que importar directamente en el fichero
import PNotify from "/node_modules/pnotify/dist/es/PNotify.js";
import { add } from "@hotwired/stimulus";
require("/node_modules/pnotify/dist/PNotifyBrightTheme.css");

function ManageModule($scope) {
  this.$scope = $scope;
  this.$formModal = $scope.find("#modal-form");
  this.$configReportModal = $scope.find("#modal-config-report");

  this.$mapDiv = ".manage-scope-map";
  this.$mapPlace = null;
  this.$inputDateManage = $scope.find(".input-date-manage");
  this.$btnReportConfig = $scope.find(".btn-config-export");
  this.$btnGenerateReport = $scope.find("#btn-generate-report");
}
ManageModule.prototype = {
  $scope: null,
  $formModal: null,
  $configReportModal: null,
  $table: null,
  $filterModal: null,
  $formFilter: null,
  $mapDiv: ".manage-scope-map",
  $mapPlace: null,
  $mapPickupLocationsContainer: ".pickupLocations-container",
  $mapArrivalLocationsContainer: ".arrivalLocations-container",
  $inputDateManage: ".input-date-manage",
  $manageAllDataContainer: ".manage-all-data-container",
  $manageRequestList: ".manage-request-list",
  $btnAssignRequest: ".btn-assign-request",
  $checkItemOriginDestinationFilter: ".origin-destination-filter-item",
  $btnReportConfig: ".btn-config-export",
  $btnGenerateReport: "#btn-generate-report",

  constructor: ManageModule,
  init: function () {
    console.log('loading manage module');
    this.initListeners();
    this.initMaps();
  },

  initListeners: function () {
    let _this = this;

    /*
    _this.$btnReportConfig.click(function (e) {
      //console.log("por directo");
      let targetBtn = this;
      _this.loadConfigReportFormModal(
        $(this).data("action-url"),
        "",
        "",
        $(this).data("modal-title")
      );
      //evt.preventDefault();
      //evt.stopPropagation();
    });
    */

    $(".ktportlet-filters-container").on(
      "click",
      ".btn-config-export",
      function (evt) {
        console.log("a traves del padre");
        let targetBtn = this;
        _this.loadConfigReportFormModal(
          $(this).data("action-url"),
          "",
          "",
          $(this).data("modal-title")
        );
        //evt.preventDefault();
        //evt.stopPropagation();
      }
    );

    _this.$btnGenerateReport.click(function (evt) {
      let $form = _this.$configReportModal.find("form");
      if ($form.valid()) {
        //$form.trigger("submit");
        /*
        if (
          $($form).find("#shared_requests_by_location_showrequests").first() &&
          $($form)
            .find("#shared_requests_by_location_showrequests")
            .first()
            .val() == "seleccion"
        ) {
          */
        //console.log("entro en la seleccionn");
        let $sharedRequestList = $("#manageTypeTab_shar");
        let $listInView = $sharedRequestList.find(
          ".kt-widget2__item.kt-widget2__item--brand.btn.btn-secondary.btn-square"
        );
        //console.log($listInView);
        //console.log("estos son todos:");
        //console.log($listInView.length);
        console.log("estos son los seleccionados:");
        let $allItems = new Array();
        let $selectedItems = new Array();
        $.each($listInView, function (index, element) {
          let $element = $(element);
          $allItems.push($element.data("idreq"));
          let $isShowed = false;
          if (
            $element.attr("style") !== undefined &&
            $element.attr("style").trim() !== ""
          ) {
            if ($element.attr("style") == "display: flex;") {
              $isShowed = true;
            }
          } else {
            $isShowed = true;
          }
          //hasta Aquí se quien esta visible, ahora veo de estos si estan checkeados
          if ($isShowed) {
            if ($element.find(".check-show-in-map").first().prop("checked")) {
              $selectedItems.push($element.data("idreq"));
            }
          }
          //
        });
        //console.log($selectedItems);
        let selectedItemsInput = document.createElement("input");
        selectedItemsInput.type = "hidden"; // Usamos hidden para que no se muestre en el formulario
        selectedItemsInput.id = "shared_requests_by_location_selreq";
        selectedItemsInput.name = "shared_requests_by_location[selreq]";
        selectedItemsInput.value = $selectedItems;
        $($form).append(selectedItemsInput);

        let allItemsInput = document.createElement("input");
        allItemsInput.type = "hidden"; // Usamos hidden para que no se muestre en el formulario
        allItemsInput.id = "shared_requests_by_location_allreq";
        allItemsInput.name = "shared_requests_by_location[allreq]";
        allItemsInput.value = $allItems;
        $($form).append(allItemsInput);

        //}

        //console.log($($form));
        //console.log($form);
        //console.log($($form).serialize());

        //return;
        //$.LoadingOverlay("show");
        //let btn = evt.target;
        $.LoadingOverlay("show");
        $.ajax({
          url: $($form).attr("action"),
          type: $($form).attr("method"),
          data: $($form).serialize(),
          xhrFields: {
            responseType: "blob",
          },
        })
          .done(function (data, status, xhr) {
            let filename = "";
            let disposition = xhr.getResponseHeader("Content-Disposition");
            if (disposition && disposition.indexOf("attachment") !== -1) {
              let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              let matches = filenameRegex.exec(disposition);
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, "");
              }
            }

            let reader = new FileReader();
            reader.onload = function (e) {
              let link = document.createElement("a");
              link.href = e.target.result;
              link.download = filename;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            };
            reader.readAsDataURL(data);

            _this.$configReportModal.modal("hide");
          })
          .fail(function (res) {
            console.log(res.content);
            /*
            Swal.fire({
              title: "Oops!!",
              text: JSON.parse(res.content)["msg"],
              icon: "error",
            });
            */
          })
          .always(function () {
            $.LoadingOverlay("hide");
          });
      }
    });

    $(".ktportlet-filters-container").on(
      "shown.bs.tab",
      'a.day-totals-tabs-link[data-toggle="tab"]',
      function (e) {
        console.log("evento muestra tab");
        _this.resetOriginDestinationFilter();
        _this.clearMarkersMap();
        _this.paintMarkersMap();
      }
    );

    _this.$inputDateManage.change(function (evt) {
      console.log("cambia fecha");
      $(_this.$manageAllDataContainer).LoadingOverlay("show");
      $.ajax({
        url: $(this).data("action-url"),
        type: "GET",
        dataType: "json",
        data: {
          date: $(this).val(),
        },
      })
        .done(function (res) {
          console.log("done");
          //_this.initMaps();
          _this.clearMarkersMap();
          //$(_this.$manageAllDataContainer).html(res);
          $(".all-totals-container").html(res.templatetotalscard);
          $(".ktportlet-tab-list-container").html(res.templateKtPortletTabList);
          $(".manage-leftPanel-container").html(res.templateKtPortletLeftPanel);
          $(".manage-origin-destination-filter-container").html(
            res.templateOriginDestinationFilter
          );
          //console.log(res.templatetotalscard);
          _this.paintMarkersMap();
        })
        .fail(function (res) {
          console.log("fail");
          console.log(res);
          Swal.fire({
            title: res.responseJSON.title,
            html: res.responseJSON.msg,
            icon: "warning",
          });
        })
        .always(function () {
          $(_this.$manageAllDataContainer).LoadingOverlay("hide");
        });
    });

    $(".ktportlet-filters-container").on(
      "change",
      _this.$checkItemOriginDestinationFilter,
      function () {
        let reqs = $(this).data("reqlist");
        let clickedElement = this;
        let $contenedorDeRequests = $(_this.$manageRequestList + ".active");
        $contenedorDeRequests
          .find(".kt-widget2__item")
          .each(function (index, reqItem) {
            if (reqs.includes($(reqItem).data("idreq"))) {
              if ($(clickedElement).prop("checked")) {
                console.log(
                  $(clickedElement)
                    .closest(".origin-destination-image-option")
                    .first()
                );
                $(clickedElement)
                  .closest(".origin-destination-image-option")
                  .first()
                  .css("background-color", "#B0E0E6");
                $(clickedElement)
                  .closest(".origin-destination-image-option")
                  .first().offsetHeight;
                $(
                  $(reqItem)
                    .find('input[type="checkbox"].check-show-in-map')
                    .first()
                ).prop("checked", true);
                //reqItem.style.visibility = "visible";
                reqItem.style.display = "flex";
                //reqItem.offsetHeight;
              } else {
                $(clickedElement)
                  .closest(".origin-destination-image-option")
                  .first()
                  .css("background-color", "transparent");
                $(clickedElement)
                  .closest(".origin-destination-image-option")
                  .first().offsetHeight;
                $(
                  $(reqItem)
                    .find('input[type="checkbox"].check-show-in-map')
                    .first()
                ).prop("checked", false);
                //reqItem.style.visibility = "hidden";
                reqItem.style.display = "none";
              }
              _this.resetOriginDestinationFilter();
              _this.clearMarkersMap();
              _this.paintMarkersMap();
            }

            /*if (
                $(
                  $(reqItem)
                    .find('input[type="checkbox"].check-show-in-map')
                    .first()
                ).prop("checked")
              ) {
                //console.log("request");
                let reqPickupPlacesData = $(reqItem).data("pickup-places-data");
                let reqArrivalPlacesData = $(reqItem).data(
                  "arrival-places-data"
                );
                let idReq = $(reqItem).data("idreq");
                let show = true;
                //console.log(reqPickupPlacesData);
                //console.log(reqArrivalPlacesData);
                $.each(reqPickupPlacesData, function (key, place) {
                  let placeData = {};
                  placeData["stringCoordinates"] =
                    place.coordinates == null ? null : place.coordinates;
                  placeData["popupText"] = place.popupText;
                  placeData["iconUrl"] = "marker-icon-2x.png";
                  placeData["origOrDest"] = "orig";
                  placeData["idReq"] = idReq;
                  _this.showMapMarker(map, placeData, show);
                });
                $.each(reqArrivalPlacesData, function (key, place) {
                  let placeData = {};
                  placeData["stringCoordinates"] =
                    place.coordinates == null ? null : place.coordinates;
                  placeData["popupText"] = place.popupText;
                  placeData["iconUrl"] = "marker-icon-red-2x.png";
                  placeData["origOrDest"] = "dest";
                  placeData["idReq"] = idReq;
                  _this.showMapMarker(map, placeData, show);
                });
              }
                */
          });
        /*
        if ($(this).prop("checked")) {
          console.log("checkeado");
        } else {
          console.log("no esta checkeado");
        }
          */
      }
    );

    $(".ktportlet-filters-container").on(
      "mouseenter",
      ".kt-widget2__item",
      function () {
        //console.log("mouse item in");
        let tempIdReq = $(this).data("idreq");
        $.each($(this).data("pickup-places-data"), function (index, place) {
          _this.$mapPlace.eachLayer(function (layer) {
            if (layer instanceof L.Marker) {
              /*let tmpCoord =
                layer.getLatLng().lat + " " + layer.getLatLng().lng;
              console.log(layer);
              console.log(layer.options.idReq);
              console.log(layer.options.origOrDest);
              */
              //origOrDest
              //idRe1
              if (tempIdReq == layer.options.idReq) {
                if (layer.options.origOrDest == "orig") {
                  //if (place.coordinates == tmpCoord) {
                  //let originalSize = layer.options.icon.options.iconSize;
                  // Definir el nuevo tamaño del icono
                  // Aumentar la altura en 20 unidades
                  //var nuevoTamanoIcono = [originalSize[0], originalSize[1] + 10];
                  //Definir el punto de anclaje ajustado
                  // Anclar en el centro del ancho y en la parte inferior del alto

                  /*var nuevoIconAnchor = [
                  nuevoTamanoIcono[0] / 2,
                  nuevoTamanoIcono[1],
                ];
                */
                  // Crear un nuevo icono con el tamaño deseado

                  let newIcon = L.icon({
                    //iconUrl: layer.options.icon.options.iconUrl, // Mantener la URL del icono actual
                    iconUrl: "marker-icon-2x-resaltado.png",
                    iconSize: layer.options.icon.options.iconSize,
                    iconAnchor: layer.options.icon.options.iconAnchor,
                    popupAnchor: layer.options.icon.options.popupAnchor,
                  });
                  /*let CustomIcon = L.DivIcon.extend({
                  options: {
                    className: "custom-icon",
                  },
                });
                let newIcon = new CustomIcon({
                  iconUrl: layer.options.icon.options.iconUrl, // Mantener la URL del icono actual
                  iconSize: layer.options.icon.options.iconSize,
                  iconAnchor: layer.options.icon.options.iconAnchor,
                  popupAnchor: layer.options.icon.options.popupAnchor,
                });
                */
                  /* let newIcon = L.divIcon({
                  //iconUrl: layer.options.icon.options.iconUrl, // Mantener la URL del icono actual
                  iconSize: null, //layer.options.icon.options.iconSize,
                  //iconAnchor: layer.options.icon.options.iconAnchor,
                  //popupAnchor: layer.options.icon.options.popupAnchor,
                  className: "mycustom-icon",
                  html: "<i class='fa fa-camera awesome'>",
                  iconSize: [30, 42],
                  iconAnchor: [15, 42],
                });*/

                  // Asignar el nuevo icono a la capa
                  layer.setIcon(newIcon);
                  //layer.setOpacity(1);
                }
              } else {
                layer.setOpacity(0.8);
              }
            }
          });
        });
        $.each($(this).data("arrival-places-data"), function (index, place) {
          _this.$mapPlace.eachLayer(function (layer) {
            if (layer instanceof L.Marker) {
              let tmpCoord =
                layer.getLatLng().lat + " " + layer.getLatLng().lng;
              if (tempIdReq == layer.options.idReq) {
                if (layer.options.origOrDest == "dest") {
                  //if (place.coordinates == tmpCoord) {
                  //let originalSize = layer.options.icon.options.iconSize;
                  // Definir el nuevo tamaño del icono
                  // Aumentar la altura en 20 unidades
                  //var nuevoTamanoIcono = [originalSize[0], originalSize[1] + 10];
                  //Definir el punto de anclaje ajustado
                  // Anclar en el centro del ancho y en la parte inferior del alto
                  /*
                var nuevoIconAnchor = [
                  nuevoTamanoIcono[0] / 2,
                  nuevoTamanoIcono[1],
                ];
                */
                  // Crear un nuevo icono con el tamaño deseado
                  /*
                let newIcon = L.icon({
                  iconUrl: layer.options.icon.options.iconUrl, // Mantener la URL del icono actual
                  iconSize: nuevoTamanoIcono,
                  iconAnchor: nuevoIconAnchor,
                  popupAnchor: layer.options.icon.options.popupAnchor,
                });
                */
                  // Asignar el nuevo icono a la capa
                  let newIcon = L.icon({
                    //iconUrl: layer.options.icon.options.iconUrl, // Mantener la URL del icono actual
                    iconUrl: "marker-icon-red-2x-resaltado.png",
                    iconSize: layer.options.icon.options.iconSize,
                    iconAnchor: layer.options.icon.options.iconAnchor,
                    popupAnchor: layer.options.icon.options.popupAnchor,
                  });
                  layer.setIcon(newIcon);
                  //layer.setOpacity(1);
                  //layer.getElement().classList.add('darken-marker');
                  //layer.getElement().classList.add('highlighted');
                  /*
                // Esperar unos segundos y luego restaurar el tamaño original
                setTimeout(function () {
                  layer.setIconSize(originalSize);
                }, 2000); // 2000 milisegundos = 2 segundos
                */
                }
              } else {
                layer.setOpacity(0.8);
              }
            }
          });
        });
      }
    );
    $(".ktportlet-filters-container").on(
      "mouseleave",
      ".kt-widget2__item",
      function () {
        //console.log("mouse item out");
        let tempIdReq = $(this).data("idreq");
        $.each($(this).data("pickup-places-data"), function (index, place) {
          //let coordinates = place.coordinates.split(" ");
          _this.$mapPlace.eachLayer(function (layer) {
            if (layer instanceof L.Marker) {
              let tmpCoord =
                layer.getLatLng().lat + " " + layer.getLatLng().lng;
              if (tempIdReq == layer.options.idReq) {
                if (layer.options.origOrDest == "orig") {
                  //if (place.coordinates == tmpCoord) {
                  //let originalSize = layer.options.icon.options.iconSize;
                  // Definir el nuevo tamaño del icono
                  // Disminuir la altura en 10 unidades
                  //var nuevoTamanoIcono = [originalSize[0], originalSize[1] - 10];
                  //Definir el punto de anclaje ajustado
                  // Anclar en el centro del ancho y en la parte inferior del alto
                  //var nuevoIconAnchor = [
                  //  nuevoTamanoIcono[0] / 2,
                  //  nuevoTamanoIcono[1],
                  //];
                  // Crear un nuevo icono con el tamaño deseado
                  let newIcon = L.icon({
                    //iconUrl: layer.options.icon.options.iconUrl, // Mantener la URL del icono actual
                    iconUrl: "marker-icon-2x.png",
                    iconSize: layer.options.icon.options.iconSize,
                    iconAnchor: layer.options.icon.options.iconAnchor,
                    popupAnchor: layer.options.icon.options.popupAnchor,
                  });
                  // Asignar el nuevo icono a la capa
                  layer.setIcon(newIcon);
                  //layer.setOpacity(0.7);

                  /*
                // Esperar unos segundos y luego restaurar el tamaño original
                setTimeout(function () {
                  layer.setIconSize(originalSize);
                }, 2000); // 2000 milisegundos = 2 segundos
                */
                }
              } else {
                layer.setOpacity(1);
              }
            }
          });
        });
        $.each($(this).data("arrival-places-data"), function (index, place) {
          //let coordinates = place.coordinates.split(" ");
          _this.$mapPlace.eachLayer(function (layer) {
            if (layer instanceof L.Marker) {
              /*let tmpCoord =
                layer.getLatLng().lat + " " + layer.getLatLng().lng;*/
              if (tempIdReq == layer.options.idReq) {
                if (layer.options.origOrDest == "dest") {
                  //if (place.coordinates == tmpCoord) {
                  let originalSize = layer.options.icon.options.iconSize;
                  // Definir el nuevo tamaño del icono
                  // Disminuir la altura en 10 unidades
                  var nuevoTamanoIcono = [
                    originalSize[0],
                    originalSize[1] - 10,
                  ];
                  //Definir el punto de anclaje ajustado
                  // Anclar en el centro del ancho y en la parte inferior del alto
                  var nuevoIconAnchor = [
                    nuevoTamanoIcono[0] / 2,
                    nuevoTamanoIcono[1],
                  ];
                  // Crear un nuevo icono con el tamaño deseado
                  let newIcon = L.icon({
                    //iconUrl: layer.options.icon.options.iconUrl, // Mantener la URL del icono actual
                    iconUrl: "marker-icon-red-2x.png",
                    iconSize: layer.options.icon.options.iconSize,
                    iconAnchor: layer.options.icon.options.iconAnchor,
                    popupAnchor: layer.options.icon.options.popupAnchor,
                  });
                  // Asignar el nuevo icono a la capa
                  layer.setIcon(newIcon);
                  //layer.setOpacity(0.7);

                  /*
                // Esperar unos segundos y luego restaurar el tamaño original
                setTimeout(function () {
                  layer.setIconSize(originalSize);
                }, 2000); // 2000 milisegundos = 2 segundos
                */
                }
              } else {
                layer.setOpacity(1);
              }
            }
          });
        });
      }
    );

    $(".ktportlet-filters-container").on(
      "change",
      'input[type="checkbox"].check-show-in-map',
      function () {
        let pickupPlaces = $($(this).closest(".kt-widget2__item")).data(
          "pickup-places-data"
        );
        let arrivalPlaces = $($(this).closest(".kt-widget2__item")).data(
          "arrival-places-data"
        );
        let idReq = $($(this).closest(".kt-widget2__item")).data("idreq");
        let show = $(this).prop("checked");
        $.each(pickupPlaces, function (key, place) {
          let placeData = {};
          placeData["stringCoordinates"] =
            place.coordinates == null ? null : place.coordinates;
          placeData["popupText"] = place.popupText;
          placeData["iconUrl"] = "marker-icon-2x-resaltado.png";
          placeData["origOrDest"] = "orig";
          placeData["idReq"] = idReq;
          _this.showMapMarker(_this.$mapPlace, placeData, show);
        });
        $.each(arrivalPlaces, function (key, place) {
          let placeData = {};
          placeData["stringCoordinates"] =
            place.coordinates == null ? null : place.coordinates;
          placeData["popupText"] = place.popupText;
          placeData["iconUrl"] = "marker-icon-red-2x-resaltado.png";
          placeData["origOrDest"] = "dest";
          placeData["idReq"] = idReq;
          _this.showMapMarker(_this.$mapPlace, placeData, show);
        });
      }
    );

    //btnAssignRequest
    $(".ktportlet-filters-container").on(
      "click",
      _this.$btnAssignRequest,
      function () {
        let targetBtn = this;
        let ajaxAction = function (btn) {
          $.ajax({
            url: $(btn).data("action-url"),
            type: "GET",
          })
            .done(function (res) {
              if (res.ok) {
                toastr.success(
                  JSON.parse(res.content)["msg"],
                  JSON.parse(res.content)["title"],
                  {
                    timeOut: 3000,
                    //progressBar : true,
                    newestOnTop: true,
                  }
                );
                //console.log('cambio..... ahora acutalizo')
                $(_this.$manageAllDataContainer).LoadingOverlay("show");
                $.ajax({
                  url: $(_this.$inputDateManage).data("action-url"),
                  type: "GET",
                  dataType: "json",
                  data: {
                    date: $(_this.$inputDateManage).val(),
                  },
                })
                  .done(function (res) {
                    console.log("done");
                    //_this.initMaps();
                    _this.clearMarkersMap();
                    //$(_this.$manageAllDataContainer).html(res);
                    $(".all-totals-container").html(res.templatetotalscard);
                    $(".ktportlet-tab-list-container").html(
                      res.templateKtPortletTabList
                    );
                    $(".manage-leftPanel-container").html(
                      res.templateKtPortletLeftPanel
                    );
                    //console.log(res.templatetotalscard);
                    _this.paintMarkersMap();
                  })
                  .fail(function (res) {
                    console.log("fail");
                    console.log(res);
                    Swal.fire({
                      title: res.responseJSON.title,
                      html: res.responseJSON.msg,
                      icon: "warning",
                    });
                  })
                  .always(function () {
                    $(_this.$manageAllDataContainer).LoadingOverlay("hide");
                  });
              } else {
                Swal.fire({
                  title: JSON.parse(res.content)["title"],
                  text: JSON.parse(res.content)["msg"],
                  icon: "error",
                });
              }
            })
            .fail(function (res) {
              Swal.fire({
                title: "Oops!!",
                text: JSON.parse(res.content)["msg"],
                icon: "error",
              });
            })
            .always(function () {
              $.LoadingOverlay("hide");
            });
        };
        if ($(targetBtn).data("make-confirmation")) {
          Swal.fire({
            title: $(targetBtn).data("confirmation-msg-title"),
            text: $(targetBtn).data("confirmation-msg-desc"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: $(targetBtn).data("confirmation-msg-btn-ok"),
            cancelButtonText: $(targetBtn).data("confirmation-msg-btn-cancel"),
          }).then(function (confirmed) {
            if (confirmed.value) {
              ajaxAction(targetBtn);
            }
          });
        } else {
          ajaxAction(targetBtn);
        }
      }
    );
  },

  initMaps: function () {
    let _this = this;

    _this.$scope.find(_this.$mapDiv).each(function (index, mapContainer) {
      let zoom = 7;
      //let zoom = 9;
      /*if ($(_this.$mapPickupLocationsContainer).data("origin-type") == "mun") {
        zoom = 12;
      } else if (
        $(_this.$mapPickupLocationsContainer).data("origin-type") == "ordes"
      ) {
        zoom = 13;
      }*/
      /*let pickupCoordinates = $(_this.$mapPickupLocationsContainer).data(
        "coordinates"
      );
      let arrivalCoordinates = $(_this.$mapArrivalLocationsContainer).data(
        "coordinates"
      );*/
      let lat = 22.096667795582714;
      let lon = -79.80700498766025;
      /*if ($(mapContainer).hasClass("origin-map")) {
        lat = pickupCoordinates.split(" ")[0];
        lon = pickupCoordinates.split(" ")[1];
      } else if ($(mapContainer).hasClass("arrival-map")) {
        lat = arrivalCoordinates.split(" ")[0];
        lon = arrivalCoordinates.split(" ")[1];
      }*/
      /*let objectCreated = L.DomUtil.get(mapContainer)._leaflet_id;
      let currentMap = null;
      for (let i in L._leaflet_maps) {
        if (L._leaflet_maps[i]._leaflet_id == objectCreated) {
          currentMap = L._leaflet_maps[i];
          break;
        }
      }
      if (currentMap) {
        currentMap.remove();
      }*/
      _this.$mapPlace = L.map(mapContainer).setView([lat, lon], zoom);

      // Definir los límites de la región (ejemplo: latitudes 40 a 45, longitudes -75 a -70)
      var southWest = L.latLng(19.5, -85), //limite mapa cuba abajo-izquierda
        northEast = L.latLng(24, -73.9), //limite mapa de cuba arriba-derecha
        bounds = L.latLngBounds(southWest, northEast);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "© OpenStreetMap contributors",
      }).addTo(_this.$mapPlace);

      // Restringir el mapa a la región definida
      _this.$mapPlace.setMaxBounds(bounds);
      _this.$mapPlace.on("drag", function () {
        _this.$mapPlace.panInsideBounds(bounds, { animate: false });
      });
      _this.$mapPlace.on("dragend", function () {
        _this.$mapPlace.panInsideBounds(bounds, { animate: true });
      });

      //limpio mapa para volver a marcar
      _this.$mapPlace.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          layer.remove();
        }
      });

      //pinto los marcadores
      _this.paintMarkersMap(_this.$mapPlace);

      _this.$mapPlace.on("click", function (e) {
        if (!$(_this.$mapDiv).hasClass("no-clickable")) {
          // Obtener las coordenadas del clic
          var lat = e.latlng.lat;
          var lon = e.latlng.lng;
          console.log(lat);
          console.log(lon);
          //limpio mapa para volver a marcar
          //map.eachLayer(function (layer) {
          //  if (layer instanceof L.Marker) {
          //    layer.remove();
          //  }
          //});

          //agrefo marcador en punto seleccionado
          //L.marker([lat, lon]).addTo(map);

          /*
                  L.circle([51.508, -0.11], {
                      color: 'red',
                      fillColor: '#f03',
                      fillOpacity: 0.5,
                      radius: 500
                  }).addTo(map)
                  */

          //.bindPopup('A pretty CSS popup.<br> Easily customizable.')
          //.openPopup();
        }
      });
    });
  },
  clearMarkersMap: function (map) {
    let _this = this;
    //console.log("estoy en la funcion de limpiar mapa");
    if (map == null) {
      //asumo es la variable que ya estoy usanndo;
      map = _this.$mapPlace;
    }
    map.eachLayer(function (layer) {
      if (layer instanceof L.Marker) {
        layer.remove();
      }
    });
  },
  paintMarkersMap: function (map) {
    let _this = this;
    //console.log("voy a pintar marcadores");
    if (map == null) {
      //console.log("uso la variable que ya tengo cargada");
      map = _this.$mapPlace;
    }
    let $contenedorDeRequests = $(_this.$manageRequestList + ".active");
    $contenedorDeRequests
      .find(".kt-widget2__item")
      .each(function (index, reqItem) {
        if (
          $(
            $(reqItem).find('input[type="checkbox"].check-show-in-map').first()
          ).prop("checked")
        ) {
          //console.log("request");
          let reqPickupPlacesData = $(reqItem).data("pickup-places-data");
          let reqArrivalPlacesData = $(reqItem).data("arrival-places-data");
          let idReq = $(reqItem).data("idreq");
          let show = true;
          //console.log(reqPickupPlacesData);
          //console.log(reqArrivalPlacesData);
          $.each(reqPickupPlacesData, function (key, place) {
            let placeData = {};
            placeData["stringCoordinates"] =
              place.coordinates == null ? null : place.coordinates;
            placeData["popupText"] = place.popupText;
            placeData["iconUrl"] = "marker-icon-2x.png";
            placeData["origOrDest"] = "orig";
            placeData["idReq"] = idReq;
            _this.showMapMarker(map, placeData, show);
          });
          $.each(reqArrivalPlacesData, function (key, place) {
            let placeData = {};
            placeData["stringCoordinates"] =
              place.coordinates == null ? null : place.coordinates;
            placeData["popupText"] = place.popupText;
            placeData["iconUrl"] = "marker-icon-red-2x.png";
            placeData["origOrDest"] = "dest";
            placeData["idReq"] = idReq;
            _this.showMapMarker(map, placeData, show);
          });
        }
      });
  },

  resetOriginDestinationFilter: function () {
    let _this = this;
    let active = null;
    let activeTab = $(".request-nav-list")
      .find(".nav-link.day-totals-tabs-link.active")
      .first();
    //console.log(activeTab.data('request-type'));
    $(".origin-destination-image-selector")
      .find(".origin-destination-image-option")
      .each(function () {
        //console.log(this);
        //console.log($(this));
        let visibility = "none";
        if ($(this).hasClass(activeTab.data("request-type"))) {
          visibility = "block";
        }
        this.style.display = visibility;
      });
  },

  showMapMarker: function (map, markerData, show) {
    let _this = this;
    //console.log("voy a pintar marcadores");
    if (map == null) {
      //asumo es la variable que ya estoy usanndo;
      map = _this.$mapPlace;
    }
    if (show) {
      if (markerData["stringCoordinates"] != null) {
        let arrCord = markerData["stringCoordinates"].split(" ");
        let myIcon = L.icon({
          iconUrl: markerData["iconUrl"],
          iconSize: [25, 41], // tamaño original del icono
          iconAnchor: [12, 41], // punto de anclaje del icono relativo al marcador
          popupAnchor: [1, -34], // desplazamiento del popup del marcador
        });
        let dataMarker = {
          icon: myIcon,
          //title: "Titulo",
          //riseOnHover: true,
          //riseOffset: 200,
          origOrDest: markerData["origOrDest"],
          idReq: markerData["idReq"],
        };
        let marker = L.marker([arrCord[0], arrCord[1]], dataMarker)
          .addTo(map)
          .bindPopup(markerData["popupText"]);
      }
    } else {
      map.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          /*let tmpCoord = layer.getLatLng().lat + " " + layer.getLatLng().lng;
          if (tmpCoord == markerData["stringCoordinates"]) {
            layer.remove();
          }*/
          if (layer.options.idReq == markerData["idReq"]) {
            layer.remove();
          }
        }
      });
    }
  },

  loadConfigReportFormModal: function (url, type, silent, titleModal) {
    let _this = this;
    $(_this.$configReportModal).addClass("modal-fade");
    // _this.toggleBodyBlock();
    let $modalBody = $(".modal-body", _this.$configReportModal);
    if (titleModal != null) {
      _this.$configReportModal.find(".modal-title").text(titleModal);
    }
    if (silent !== true) {
      $.LoadingOverlay("show");
    } else {
      //$modalBody.html(_this.loadPlaceholder);
    }
    $modalBody.load(url, function (res) {
      App.initComponents($modalBody);
      _this.$configReportModal.trigger("api_form_modal_loaded", { type: type });
      if (silent !== true) {
        $.LoadingOverlay("hide");
        _this.$configReportModal.modal("show");
      }
    });
  },
};

(function () {
  let $scopeManage = $(".scope-manage");
  if ($scopeManage.length) {
    new ManageModule($scopeManage).init();
  }
})(jQuery, window, document);
