import Tree from "@widgetjs/tree";

//notificaciones con toastr
import * as Toastr from "toastr";

//mapas
import L from "leaflet";

require("/node_modules/leaflet/dist/leaflet.css");

// Importa Leaflet Routing Machine y sus estilos
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine";

//notificaciones con PNotify, pero hay que importar directamente en el fichero
import PNotify from "/node_modules/pnotify/dist/es/PNotify.js";
import {add} from "@hotwired/stimulus";

require("/node_modules/pnotify/dist/PNotifyBrightTheme.css");


import '@dmuy/timepicker/dist/mdtimepicker.css'
import mdtimepicker from '@dmuy/timepicker'

function ClientModule($scope) {
    this.$scope = $scope;
    this.$formModal = $scope.find("#modal-form");
    this.$formPaymentModal = $scope.find("#modal-payment-form");
    this.$btnChangeStatusVoucher = $scope.find(".change-status-voucher-btn");
    this.$selectVehiclePrototype = $scope.find(".client-evaluation-select-vehicle-prototype");
    this.$departureTime = $scope.find(".client-evaluation-departure-time");
    this.$selectRoute = $scope.find(".client-evaluation-rute");
    this.$hiddenTariffication = $scope.find(".client-evaluation-tariffication");
    this.$mdTimePicker = $scope.find(".mdtimepicker");
    this.$btnShowPayModal = $scope.find(".btn-form-pay-service");

    this.$mapDiv = ".client-scope-map";
}

ClientModule.prototype = {
    $scope: null,
    $formModal: null,
    $formPaymentModal: null,
    $table: null,
    $filterModal: null,
    $formFilter: null,
    $btnChangeStatusVoucher: ".change-status-voucher-btn",

    $mapDiv: ".client-scope-map",

    $mapPickupLocationsContainer: ".pickupLocations-container",
    $mapArrivalLocationsContainer: ".arrivalLocations-container",
    $inputCalculatedPrice: ".calculated-price-field",
    $hiddenTariffication: ".client-evaluation-tariffication",
    $btnShowPayModal: ".btn-form-pay-service",
    $selectPayMethodFormPayment: ".form-pay-request-pay-method",
    $btnSaveForm: "#btn-save",

    constructor: ClientModule,
    init: function () {
        console.log('loading client module');
        this.initListeners();
        this.initMaps();
    },

    initListeners: function () {
        let _this = this;


        let $genericForm = $('form');

        $genericForm.validate({
            rules: {
                fieldName: {
                    required: true,
                    email: true
                },
                anotherField: {
                    required: true,
                    minlength: 5
                }
            },
            messages: {
                fieldName: {
                    required: "Este campo es obligatorio.",
                    email: "Por favor, introduce un correo electrónico válido."
                },
                anotherField: {
                    required: "Este campo es obligatorio.",
                    minlength: "Debe tener al menos 5 caracteres."
                }
            },
            errorPlacement: function (error, element) {
                //error.addClass("text-danger"); // Añade una clase CSS al mensaje de error
                //error.insertAfter(element);   // Inserta el mensaje justo debajo del campo
            },
            highlight: function (element) {
                $(element).addClass("is-invalid"); // Clase para resaltar el campo con error
            },
            unhighlight: function (element) {
                $(element).removeClass("is-invalid"); // Quita la clase cuando el campo sea válido
            }
        });

        _this.$btnShowPayModal.on("click", function (evt) {
            console.log("selecciono para pagar");
            console.log(this);
            console.log($(this).data("load-url"));
            console.log($(this).data("title-modal"));
            _this.loadFormPaymentModal(
                $(this).data("load-url"),
                "edit",
                $(this).data("title-modal")
            );
        });

        _this.$formPaymentModal.on("change", _this.$selectPayMethodFormPayment, function (evt) {
            console.log("selecciono metodo de pago");
            console.log(this);
            const optionSelected = $(this).find(':selected');
            console.log(optionSelected.data('id'));
            $(".client-form-request-payment-save-link").css("display", "none");
            $(".client-form-request-payment-save-cash").css("display", "none");
            $(".client-form-request-payment-save-card").css("display", "none");
            if (optionSelected.data('code') == 'LINK') {
                $(".client-form-request-payment-save-link").css("display", "block");
            }
            if (optionSelected.data('code') == 'CASH') {
                $(".client-form-request-payment-save-cash").css("display", "block");
            }
            if (optionSelected.data('code') == 'CARD') {
                $(".client-form-request-payment-save-card").css("display", "block");
            }
        });

        _this.$formPaymentModal.on("click", _this.$btnSaveForm, function (evt) {
            console.log("envio formulario de pago");
            console.log(this);
            let $form = _this.$formPaymentModal.find("form");
            if ($form.valid()) {
                //$form.trigger("submit");
                $(".kt-portlet__body").LoadingOverlay("show");
                $.ajax({
                    url: $($form).attr("action"),
                    type: $($form).attr("method"),
                    data: $($form).serialize(),
                })
                    .done(function (res) {
                        console.log("guarde, ahora pido el que guarde");
                        $(".kt-portlet__body").LoadingOverlay("hide");
                        _this.$formPaymentModal.modal("hide");
                        console.log(res.actionValid);
                        if (res.actionValid) {
                            if ('redirect_to' in res) {
                                Swal.fire({
                                    title: res.title,
                                    text: res.msg,
                                    icon: "info",
                                    showCancelButton: false,
                                    confirmButtonText: 'Ok',
                                }).then(function (confirmed) {
                                    if (confirmed.value) {
                                        window.location.href = res.redirect_to;
                                    }
                                });

                            } else {
                                Swal.fire({
                                    title: res.title,
                                    text: res.msg,
                                    icon: "info",
                                    showCancelButton: false,
                                    confirmButtonText: 'Ok',
                                }).then(function (confirmed) {
                                    if (confirmed.value) {
                                        //location.reload();
                                        //location.reload(true);
                                    }
                                });
                            }

                        } else {
                            console.log(res);
                            Swal.fire({
                                title: res.title,
                                //text: "Ha ocurrido un error al procesar los datos de pago, inténtelo más tarde...",
                                text: res.msg,
                                icon: "warning",
                            });
                            //location.reload();
                        }
                    })
                    .fail(function (res) {
                        console.log("fallo, ahora veo el por que");
                        console.log(res.responseText);
                        console.log(res.status);
                        if (res.status === 409) {
                            console.log(res);
                            $(".kt-portlet__body").LoadingOverlay("hide");
                            _this.$formPaymentModal.modal("hide");
                            Swal.fire({
                                title: "Atención",
                                text: res.responseJSON.error,
                                icon: "info",
                            });
                        } else {
                            Swal.fire({
                                title: "Oops!!",
                                text: "Ha ocurrido un error al procesar los datos de pago, inténtelo más tarde...",
                                icon: "warning",
                            });
                        }
                    })
                    .always(function () {
                        $.LoadingOverlay("hide");
                    });
            }

        });

        if (_this.$mdTimePicker.length) {
            mdtimepicker(_this.$mdTimePicker[0], {
                format: 'hh:mm', // Formato de tiempo 24 horas
                theme: 'blue',
            });
        }

        _this.$selectRoute.on("change", function (evt) {
            console.log("cambio en ruta");
            console.log(this);
            let $url = $(this).data("action-url");
            _this.calculatePrice($(this));
        });

        _this.$selectVehiclePrototype.on("change", function (evt) {
            console.log("cambio en prototipo de vehiculo");
            if (_this.$departureTime.val() != "") {
                console.log(this);
                let $url = $(this).data("action-url");
                _this.calculatePrice($(this));
            }
        });

        _this.$departureTime.on("change", function (evt) {
            console.log("cambio en horario de salida");
            if (_this.$selectVehiclePrototype.val() != "") {
                console.log(this);
                let $url = $(this).data("action-url");
                _this.calculatePrice($(this));
            }
        });

        _this.$btnChangeStatusVoucher.click(function (e) {
            console.log("anular desde el voycher con scope nuevo");
            let targetBtn = this;
            let ajaxAction = function (btn) {
                let form = btn.closest('form');
                console.log(form);
                $(".kt-portlet__body").LoadingOverlay("show");
                $.ajax({
                    url: $(btn).data("action-url"),
                    type: $(btn).data("method") ? $(btn).data("method") : "GET",
                    //url: $($form).attr("action"),
                    //type: $($form).attr("method"),
                    data: $(form).serialize(),
                })
                    .done(function (res) {
                        $(".kt-portlet__body").LoadingOverlay("hide");
                        Swal.fire({
                            title: res.title,
                            text: res.msg,
                            icon: "info",
                            showCancelButton: false,
                            confirmButtonText: 'Ok',
                        }).then(function (confirmed) {
                            if (confirmed.value) {
                                location.reload();
                                //location.reload(true);
                            }
                        });

                        /*if (res.actionValid) {
                          if (res.msg) {
                            Swal.fire({
                              title: res.title,
                              text: res.msg,
                              icon: "info",
                            });
                          }
                          _this.$formModal.modal("hide");
                          _this.$table.DataTable().ajax.reload();
                        } else {
                          Swal.fire({
                            title: res.title,
                            //text: res.msg,
                            html: res.msg,
                            icon: "warning",
                          });
                        }
                        */
                    })
                    .fail(function (res) {
                        console.log("fail");
                        console.log(res.responseJSON.title);
                        Swal.fire({
                            title: res.responseJSON.title + "!!",
                            text: res.responseJSON.msg,
                            icon: "error",
                        });
                    })
                    .always(function () {
                        $.LoadingOverlay("hide");
                    });
            };

            if ($(targetBtn).data("make-confirmation")) {
                let $form = $(targetBtn).closest("form").first();
                console.log(targetBtn);
                console.log($(targetBtn));
                console.log($(targetBtn).data("action"));
                if ($(targetBtn).data("action") == "reject" || $(targetBtn).data("action") == "cancel") {
                    Swal.fire({
                        title: $(targetBtn).data("confirmation-msg-title"),
                        text: $(targetBtn).data("confirmation-msg-desc"),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: $(targetBtn).data("confirmation-msg-btn-ok"),
                        cancelButtonText: $(targetBtn).data("confirmation-msg-btn-cancel"),
                    }).then(function (confirmed) {
                        if (confirmed.value) {
                            ajaxAction(targetBtn);
                        }
                    });
                } else {
                    if ($form.valid()) {
                        console.log("es valido");
                        Swal.fire({
                            title: $(targetBtn).data("confirmation-msg-title"),
                            text: $(targetBtn).data("confirmation-msg-desc"),
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: $(targetBtn).data("confirmation-msg-btn-ok"),
                            cancelButtonText: $(targetBtn).data("confirmation-msg-btn-cancel"),
                        }).then(function (confirmed) {
                            if (confirmed.value) {
                                ajaxAction(targetBtn);
                            }
                        });
                    } else {
                        console.log("no es valido el formulario");
                    }
                }

                if ($form.valid()) {
                    console.log("es valido");
                    Swal.fire({
                        title: $(targetBtn).data("confirmation-msg-title"),
                        text: $(targetBtn).data("confirmation-msg-desc"),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: $(targetBtn).data("confirmation-msg-btn-ok"),
                        cancelButtonText: $(targetBtn).data("confirmation-msg-btn-cancel"),
                    }).then(function (confirmed) {
                        if (confirmed.value) {
                            ajaxAction(targetBtn);
                        }
                    });
                } else {
                    console.log("no es valido el formulario");
                }

            } else {
                ajaxAction(targetBtn);
            }
        });
    },

    initMaps: function () {
        let _this = this;

        _this.$scope.find(_this.$mapDiv).each(function (index, mapContainer) {
            let zoom = 9;
            if ($(_this.$mapPickupLocationsContainer).data("origin-type") === "mun") {
                zoom = 12;
            } else if (
                $(_this.$mapPickupLocationsContainer).data("origin-type") === "ordes"
            ) {
                zoom = 13;
            }
            let pickupCoordinates = $(_this.$mapPickupLocationsContainer).data(
                "coordinates"
            );
            let arrivalCoordinates = $(_this.$mapArrivalLocationsContainer).data(
                "coordinates"
            );
            let lat = 22.096667795582714;
            let lon = -79.80700498766025;
            if ($(mapContainer).hasClass("origin-map")) {
                lat = pickupCoordinates.split(" ")[0];
                lon = pickupCoordinates.split(" ")[1];
            } else if ($(mapContainer).hasClass("arrival-map")) {
                lat = arrivalCoordinates.split(" ")[0];
                lon = arrivalCoordinates.split(" ")[1];
            }
            let map = L.map(mapContainer).setView([lat, lon], zoom);

            // Definir los límites de la región (ejemplo: latitudes 40 a 45, longitudes -75 a -70)
            var southWest = L.latLng(19.5, -85), //limite mapa cuba abajo-izquierda
                northEast = L.latLng(23.5, -73.9), //limite mapa de cuba arriba-derecha
                bounds = L.latLngBounds(southWest, northEast);

            L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                attribution: "© OpenStreetMap contributors",
            }).addTo(map);

            // Restringir el mapa a la región definida
            map.setMaxBounds(bounds);
            map.on("drag", function () {
                map.panInsideBounds(bounds, {animate: false});
            });
            map.on("dragend", function () {
                map.panInsideBounds(bounds, {animate: true});
            });

            //busco los puntos  de recogidas y llegadas para agregar marcadores al mapa
            if (
                $(mapContainer).closest(_this.$mapPickupLocationsContainer).length > 0
            ) {
                $(_this.$mapPickupLocationsContainer)
                    .find(".place-item")
                    .each(function (index, place) {
                        let lat = $(place).data("coordinates").split(" ")[0];
                        let lon = $(place).data("coordinates").split(" ")[1];
                        //console.log("origin");
                        //console.log(lat);
                        //console.log(lon);
                        if (
                            lat !== "" &&
                            typeof lat !== "undefined" &&
                            lon !== "" &&
                            typeof lon !== "undefined"
                        ) {
                            L.marker([lat, lon]).addTo(map).bindPopup($(place).data("popup"));
                        }
                    });
            } else if (
                $(mapContainer).closest(_this.$mapArrivalLocationsContainer).length > 0
            ) {
                $(_this.$mapArrivalLocationsContainer)
                    .find(".place-item")
                    .each(function (index, place) {
                        let lat = $(place).data("coordinates").split(" ")[0];
                        let lon = $(place).data("coordinates").split(" ")[1];
                        //console.log('arrival');
                        //console.log(lat);
                        //console.log(lon);
                        if (
                            lat !== "" &&
                            typeof lat !== "undefined" &&
                            lon !== "" &&
                            typeof lon !== "undefined"
                        ) {
                            L.marker([lat, lon]).addTo(map).bindPopup($(place).data("popup"));
                        }
                    });
            }

            map.on("click", function (e) {
                if (!$(_this.$mapDiv).hasClass("no-clickable")) {
                    // Obtener las coordenadas del clic
                    var lat = e.latlng.lat;
                    var lon = e.latlng.lng;
                    console.log(lat);
                    console.log(lon);
                    //limpio mapa para volver a marcar
                    //map.eachLayer(function (layer) {
                    //  if (layer instanceof L.Marker) {
                    //    layer.remove();
                    //  }
                    //});

                    //agrefo marcador en punto seleccionado
                    //L.marker([lat, lon]).addTo(map);

                    /*
                            L.circle([51.508, -0.11], {
                                color: 'red',
                                fillColor: '#f03',
                                fillOpacity: 0.5,
                                radius: 500
                            }).addTo(map)
                            */

                    //.bindPopup('A pretty CSS popup.<br> Easily customizable.')
                    //.openPopup();
                }
            });
        });
        /*
        if ($(_this.$mapDiv).length) {



          //busco en el formulario, el campo coordinates y cojo su valor para marcar en el mapa
          let coordinatesValue = $(_this.$mapDiv)
            .closest("#crud-form")
            .find(".coordinates")
            .val();
          console.log(coordinatesValue);
          if (
            coordinatesValue === null ||
            coordinatesValue === "" ||
            coordinatesValue === undefined
          ) {
          } else {
            let arreglo = coordinatesValue.split(" ");
            console.log(arreglo);
            let lat = arreglo[0];
            let lon = arreglo[1];
            map.setView([lat, lon], 12);

            map.eachLayer(function (layer) {
              if (layer instanceof L.Marker) {
                layer.remove();
              }
            });

            //agrefo marcador en punto seleccionado
            L.marker([lat, lon]).addTo(map);
          }

          //para el caso del formulario de privado
          //console.log($($(this).find('form')[0]).attr('name'));
          // if ($($(this).find("form")[0]).attr("name") == "private_service") {
          //  console.log('mando a ejecutar funcion');
          _this.routing(_this, false);
          // }
        }*/
    },

    routing: function (_this, reset = false) {
        console.log("routing");
        map.eachLayer(function (layer) {
            if (layer instanceof L.Marker || layer instanceof L.Routing.Line) {
                layer.remove();
                //map.removeLayer(layer);
            }
        });
        //console.log("funcion routing");
        let selectOrigin = $(_this.$mapDiv).closest("form").find(".origin-field");
        let selectDestination = $(_this.$mapDiv)
            .closest("form")
            .find(".destination-field");
        let waypointsField = $(_this.$mapDiv)
            .closest("form")
            .find(".waypoints-field");
        if (selectOrigin.length > 0 && selectDestination.length > 0) {
            //console.log("primer if");
            if (selectOrigin.prop("selectedIndex")) {
                //console.log("segundo if");
                let coordinatesOrigin = [
                    selectOrigin.find(":selected").data("lat").toString(),
                    selectOrigin.find(":selected").data("lon").toString(),
                ];
                let coordinatesDestination = [
                    selectDestination.find(":selected").data("lat").toString(),
                    selectDestination.find(":selected").data("lon").toString(),
                ];
                let puntoInicio = L.latLng(coordinatesOrigin[0], coordinatesOrigin[1]);
                let puntoFin = L.latLng(
                    coordinatesDestination[0],
                    coordinatesDestination[1]
                );
                let waypointsArray = [];

                waypointsArray.push(puntoInicio);
                //los puntos de encuentro
                if (reset) {
                    console.log("resetea");
                    $(_this.$meetingPointList + " select").each(function (index, select) {
                        let indiceSeleccionado = select.selectedIndex;
                        let optionSeleccionado = $(select.options[indiceSeleccionado]);
                        let coordinatesMeetingPoint = [
                            optionSeleccionado.data("lat").toString(),
                            optionSeleccionado.data("lon").toString(),
                        ];
                        let puntoIntermedio = L.latLng(
                            coordinatesMeetingPoint[0],
                            coordinatesMeetingPoint[1]
                        );
                        waypointsArray.push(puntoIntermedio);
                    });
                } else {
                    //console.log("no resetea");
                    //console.log(waypointsField.val());
                    /*
                    if ( waypointsField.val() !== "") {
                      let tempArray = waypointsField.val().split(",");
                      tempArray.forEach(function (element, index) {
                        //console.log(index);
                        //if (index != 0 && index != tempArray.length - 1) {
                        let cord = element.split(" ");
                        waypointsArray.push(L.latLng(cord[0], cord[1]));
                        //console.log("add point");
                        //}
                      });
                    }
                    */
                }
                waypointsArray.push(puntoFin);

                // Crear un control de rutas
                var controlRutas = L.Routing.control({
                    waypoints: waypointsArray,
                    language: "es",
                    routeWhileDragging: true,
                }).addTo(map);
                //console.log(map);
                //console.log(controlRutas);
                controlRutas.on("routesfound", function (event) {
                    var ruta = event.routes[0]; // Tomar la primera ruta encontrada
                    var distancia = ruta.summary.totalDistance; // Distancia en metros
                    var demora = ruta.summary.totalTime; // Demora en segundos

                    //console.log(ruta.waypoints);
                    let waypointsToSave = "";
                    ruta.waypoints.forEach(function (waypoint, index) {
                        //console.log("Waypoint", index + 1, ":", waypoint.latLng);
                        if (index != 0 && index != ruta.waypoints.length - 1) {
                            if (waypointsToSave == "") {
                                waypointsToSave =
                                    waypoint.latLng.lat + " " + waypoint.latLng.lng;
                            } else {
                                waypointsToSave =
                                    waypointsToSave +
                                    "," +
                                    waypoint.latLng.lat +
                                    " " +
                                    waypoint.latLng.lng;
                            }
                        }
                    });
                    waypointsField.val(waypointsToSave);
                    //console.log(waypointsToSave);

                    //estos seran los valores reales
                    $(".estimated-distance-field").val(distancia);
                    $(".estimated-time-field").val(demora);
                    //ahora lo que muestro
                    $(".estimatedDistance_view").val(
                        Math.round(distancia / 1000).toFixed(1) + " Km"
                    );
                    $(".estimatedTime_view").val(
                        _this.convertirSegundosAHorasYMinutos(demora)
                    );

                    //console.log("Distancia:", distancia, "metros");
                    //console.log("Demora:", demora, "segundos");
                });

                controlRutas.on("routeselected", function (e) {
                    //console.log("Se ha seleccionado una ruta");
                    //console.log("La ruta seleccionada es:", e.route);
                });
            }
        }
    },

    calculatePrice: function (elementLauncher) {
        //let $form = $('form');
        let $url = elementLauncher.data("action-url");
        let $form = elementLauncher.closest('form');
        console.log($form);
        // let $form = _this.find("form");
        // console.log("calcula");
        if ($form.valid()) {
            console.log("form valido");
            //$form.trigger("submit");
            $form.LoadingOverlay("show");
            $.ajax({
                url: $url,
                type: $($form).attr("method"),
                data: $($form).serialize(),
            })
                .done(function (res) {
                    console.log("done");
                    //console.log(res);
                    let priceField = $('.calculated-price-field').first();
                    priceField.val("$ " + res.prices.netPrice);

                    let tarifficationField = $('.client-evaluation-tariffication').first();
                    console.log(tarifficationField);
                    tarifficationField.val(parseInt(res.tariffication));
                    //$($.$inputCalculatedPrice).val(res.price);
                    // if ($(_this.$inputToPayPrice).val() == "") {
                    //     $(_this.$inputToPayPrice).val(res.price);
                    // }

                    let htmlContent = '<ul style="text-align:left;">';

// Verifica si hay descuentos
                    if (res.prices.discountAmount > 0 || res.prices.discountByCouponAmount > 0) {
                        htmlContent += `<li>Precio bruto: <s>$${res.prices.grossPrice}</s></li>`;
                        htmlContent += `<li>Descuentos aplicados: <ul>`; // Abre la lista desordenada de descuentos

                        // Agrega el descuento general si es mayor que 0
                        if (res.prices.discountAmount > 0) {
                            htmlContent += `<li>$${res.prices.discountAmount} (descuento general)</li>`;
                        }

                        // Agrega el descuento por cupón si es mayor que 0
                        if (res.prices.discountByCouponAmount > 0) {
                            htmlContent += `<li>$${res.prices.discountByCouponAmount} (cupón)</li>`;
                        }

                        htmlContent += `</ul></li>`; // Cierra la lista desordenada de descuentos
                    }

// Muestra siempre el precio final
                    htmlContent += `<li style="font-weight: bold; color: green;">Precio Final: $${res.prices.netPrice}</li>`;

                    htmlContent += '</ul>';

// Muestra el SweetAlert
                    Swal.fire({
                        title: "Precio Calculado Correctamente",
                        html: htmlContent,
                        icon: "info",
                    });


                })
                .fail(function (res) {
                    console.log("fail");
                    console.log(res);
                    console.log("reseteo el formulario")
                    elementLauncher.val(null);
                    let priceField = $('.calculated-price-field').first();
                    priceField.val("$ 0.00");
                    Swal.fire({
                        title: res.responseJSON.title,
                        html: res.responseJSON.msg,
                        icon: "warning",
                    });
                })
                .always(function () {
                    $form.LoadingOverlay("hide");
                });
        } else {
            // Swal.fire({
            //     title: "Formulario Invalido",
            //     text: "El formulario de la solicitud contiene errores, por favor revise.....",
            //     icon: "error",
            // });
        }
    },

    loadFormModal: function (url, type, silent) {
        let _this = this;
        // _this.toggleBodyBlock();
        let $modalBody = $(".modal-body", _this.$formModal);
        if (silent !== true) {
            $.LoadingOverlay("show");
        } else {
            //$modalBody.html(_this.loadPlaceholder);
        }
        $modalBody.load(url, function (res) {
            App.initComponents($modalBody);
            _this.$formModal.trigger("api_form_modal_loaded", {type: type});
            if (silent !== true) {
                $.LoadingOverlay("hide");
                _this.$formModal.modal("show");
            }
        });
    },

    loadFormPaymentModal: function (url, type, silent) {
        let _this = this;
        // _this.toggleBodyBlock();
        let $modalBody = $(".modal-body", _this.$formPaymentModal);
        if (silent !== true) {
            $.LoadingOverlay("show");
        } else {
            //$modalBody.html(_this.loadPlaceholder);
        }
        $modalBody.load(url, function (res) {
            App.initComponents($modalBody);
            _this.$formPaymentModal.trigger("api_form_modal_loaded", {type: type});
            if (silent !== true) {
                $.LoadingOverlay("hide");
                _this.$formPaymentModal.modal("show");
            }
        });
    },

};

(function () {
    let $scopeClient = $(".scope-client");
    if ($scopeClient.length) {
        new ClientModule($scopeClient).init();
    }
})(jQuery, window, document);
