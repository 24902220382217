import '@codyas/symfony-toolbox';

/*####### CSS  #######*/
//login
import './../styles/login.css';
import './../styles/app.scss';
import 'typeahead.js';
window.Swal = require('sweetalert2');

//import '../node_modules/bootstrap-timepicker/css/bootstrap-timepicker.css';
//require('bootstrap-datetimepicker');
//require('bootstrap-timepicker');

function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('./modules/', true, /\.js$/));