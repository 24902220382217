import Tree from "@widgetjs/tree";

//notificaciones con toastr
import * as Toastr from "toastr";

//mapas
import L from "leaflet";

require("/node_modules/leaflet/dist/leaflet.css");

// Importa Leaflet Routing Machine y sus estilos
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine";

//notificaciones con PNotify, pero hay que importar directamente en el fichero
import PNotify from "/node_modules/pnotify/dist/es/PNotify.js";

require("/node_modules/pnotify/dist/PNotifyBrightTheme.css");

require("jquery-validation/dist/localization/messages_es");

function AdminModule($scope) {
    this.$scope = $scope;
    this.$formModal = $scope.find("#modal-form");
    this.$personFormModal = $scope.find("#modal-form-person");
    this.$productViewerModal = $scope.find("#modal-product-viewer");
    this.$historySnapshotViewerModal = $scope.find("#modal-history-viewer");
    this.$formModalOrderChangeStatus = $scope.find("#modal-order-change-status");
    this.$formModalProduct = $scope.find("#modal-form-product");
    this.$table = $scope.find(".crud-table");
    this.$controlUserTypeFilter = $scope.find('[name="filter_user_type"]');
    this.$filterModal = $scope.find("#filter-form");
    this.$formFilter = this.$filterModal.find("form");

    this.$btnSaveModalProduct = $scope.find("#btn-save-product");

    this.$btnChangeStatusRecord = ".change-status-record-btn";
    this.$btnBasicExport = $scope.find(".btn-basic-export");

    this.$checkMultiselectionCrudTable = $scope.find(
        ".crud-multiselection-check"
    );
    this.$btnRapidEnable = $scope.find(".rapid-enable-btn");
    this.$btnRapidDisable = $scope.find(".rapid-disable-btn");

    this.$ctLeftPanel = $scope.find(".ct-left-panel");
    this.$ctListBtn = $scope.find(".ct-list-btn");
    this.$mapDiv = "#map";
    this.$mainMap = null;
    this.$btnHistorySnapshotViewer = ".history-snapshot-viewer-record-btn";
}

AdminModule.prototype = {
    $scope: null,
    $formModal: null,
    $formModalProduct: null,
    $formModalOrderChangeStatus: null,
    $historySnapshotViewerModal: null,
    $table: null,
    $controlUserTypeFilter: null,
    $filterModal: null,
    $formFilter: null,
    $controlDestinationsTree: null,
    //$agencyReference: null,
    $checkListAgenciesProducts: "#product_availableForAgencies",
    $radioListDepartamentProducts: ".radio-product-departament",
    $checkboxSelectUnselectAllAgencies: ".checkbox_select_unselect_all_agencies",
    $selectAgencyProductAgencySpecification:
        ".select-agency_product-agency-specification",
    $selectDepartamentProductAgencySpecification:
        ".select-departament_product-agency-specification",
    $inputInventoryProductAgencySpecification:
        ".input-inventory_product-agency-specification",
    $inputPriceProductAgencySpecification:
        ".input-price_product-agency-specification",
    $agencyProductList: "#agency-products-list",
    $btnCollectionRemoveItem: ".collection-remove-item",
    $collectionList: ".collection-list",
    $collectionListProductAgencySpecification:
        ".collection-list-product-agency-specification",

    $inputUserUsername: "#user_username",
    $selectUserRoles: "#user_roles",
    $selectUserAgencies: "#user_agencies",

    $inputProvinceName: "#province_name",
    $selectProvinceMunicipalities: "#province_municipalities",

    $inputMunicipalityName: "#municipality_name",
    $selectMunicipalityProvince: "#municipality_province",
    $selectMunicipalityBasicShippingPrice: "#municipality_basicShippingPrice",

    $btnChangeOrderStatus: ".change-ordes-status-btn",
    $btnSaveChangeStatus: "#btn-save-change-status",
    $btnCancelOrder: ".cancel-order-btn",

    $btnChangeStatusRecord: ".change-status-record-btn",
    $btnHistorySnapshotViewer: ".history-snapshot-viewer-record-btn",

    $ctLeftPanel: ".ct-left-panel",
    //$ctListBtn: '.ct-list-btn',
    $mapDiv: "#map",
    $mainMap: null,
    $mapProvince: ".map-province",
    $mapMunicipality: ".map-municipality",
    $mapOriginDestination: ".map-origin-destination",
    $mapLocation: ".map-location",

    $originSelect: ".origin-field",
    $destinationSelect: ".destination-field",
    $formMunicipalityProvinceSelect: ".form-municipality_province-field",
    $formLocationOriginDestinationSelect:
        ".form-location_origin-destination-field",
    $formMunicipalitySelect: ".form-municipality-field",
    $codeField: ".code-field",
    $radioOriginPersonData: 'input[name="personal_data"]',
    $selectPersonUser: ".user_personsList",
    $personInputs: ".person_inputs",

    $collectionAddBtn: ".crud-append-btn",

    $collectionMeetingRemoveBtn: ".meeting-collection-remove-item",

    $selectMeetingPoint: ".selectMeetingPoint",
    $meetingPointList: "#meeting-points-list",

    $btnNewUserPerson: ".btn-new-user-person",
    $btnSavePersonModal: "#btn-save-person",

    $btnDuplicateTariffication: ".duplicate-tariffication-btn",

    constructor: AdminModule,
    init: function () {
        console.log('loading admin module');
        let _this = this;
        this.initListeners();
        //inicial
    },

    loadFormModal: function (url, type, titleModal, silent) {
        let _this = this;
        // _this.toggleBodyBlock();
        let $modalBody = $(".modal-body", _this.$formModal);
        if (titleModal != null) {
            _this.$formModal.find(".modal-title").text(titleModal);
        }
        if (silent !== true) {
            $.LoadingOverlay("show");
        } else {
            //$modalBody.html(_this.loadPlaceholder);
        }
        $modalBody.load(url, function (res) {
            App.initComponents($modalBody);
            _this.$formModal.trigger("api_form_modal_loaded", {type: type});
            if (silent !== true) {
                $.LoadingOverlay("hide");
                _this.$formModal.modal("show");
            }
        });
    },

    loadHistorySnapshotModal: function (url, type, titleModal, silent) {
        let _this = this;
        // _this.toggleBodyBlock();
        let $modalBody = $(".modal-body", _this.$historySnapshotViewerModal);
        if (titleModal != null) {
            _this.$historySnapshotViewerModal.find(".modal-title").text(titleModal);
        }
        if (silent !== true) {
            $.LoadingOverlay("show");
        } else {
            //$modalBody.html(_this.loadPlaceholder);
        }
        $modalBody.load(url, function (res) {
            App.initComponents($modalBody);
            _this.$historySnapshotViewerModal.trigger("api_form_modal_loaded", {type: type});
            if (silent !== true) {
                $.LoadingOverlay("hide");
                _this.$historySnapshotViewerModal.modal("show");
            }
        });
    },

    loadPersonFormModal: function (url, type, silent) {
        let _this = this;
        // _this.toggleBodyBlock();
        let $modalBody = $(".modal-body", _this.$personFormModal);
        if (silent !== true) {
            $.LoadingOverlay("show");
        } else {
            //$modalBody.html(_this.loadPlaceholder);
        }
        $modalBody.load(url, function (res) {
            App.initComponents($modalBody);
            _this.$personFormModal.trigger("api_form_modal_loaded", {type: type});
            if (silent !== true) {
                $.LoadingOverlay("hide");
                _this.$personFormModal.modal("show");
            }
        });
    },

    initListeners: function () {
        let _this = this;

        //////ahora////

        _this.$ctListBtn.click(function (evt) {
            //evt.preventDefault();
            //envio
        });

        /////fin ahora///////

        _this.$table.on("click", _this.$btnChangeStatusRecord, function (e) {
            let targetBtn = this;
            let ajaxAction = function (btn) {
                $.ajax({
                    url: $(btn).data("action-url"),
                    type: "GET",
                })
                    .done(function (res) {
                        if (res.ok) {
                            toastr.success(
                                JSON.parse(res.content)["msg"],
                                JSON.parse(res.content)["title"],
                                {
                                    timeOut: 3000,
                                    //progressBar : true,
                                    newestOnTop: true,
                                }
                            );
                            //PNotify.alert('con pnotify');
                            _this.$table.DataTable().ajax.reload();
                        } else {
                            Swal.fire({
                                title: JSON.parse(res.content)["title"],
                                text: JSON.parse(res.content)["msg"],
                                icon: "error",
                            });
                        }
                    })
                    .fail(function (res) {
                        Swal.fire({
                            title: "Oops!!",
                            text: JSON.parse(res.content)["msg"],
                            icon: "error",
                        });
                    })
                    .always(function () {
                        $.LoadingOverlay("hide");
                    });
            };
            if ($(targetBtn).data("make-confirmation")) {
                Swal.fire({
                    title: $(targetBtn).data("confirmation-msg-title"),
                    text: $(targetBtn).data("confirmation-msg-desc"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: $(targetBtn).data("confirmation-msg-btn-ok"),
                    cancelButtonText: $(targetBtn).data("confirmation-msg-btn-cancel"),
                }).then(function (confirmed) {
                    if (confirmed.value) {
                        ajaxAction(targetBtn);
                    }
                });
            } else {
                ajaxAction(targetBtn);
            }
        });

        _this.$table.on("click", _this.$btnDuplicateTariffication, function (e) {
            console.log("duplicando tarifa");
            _this.loadFormModal(
                $(this).data("load-url"),
                "edit",
                $(this).data("title-modal")
            );
        });

        _this.$table.on("click", _this.$btnHistorySnapshotViewer, function (e) {
            console.log("ver snapshot");
            console.log($(this));
            _this.loadHistorySnapshotModal(
                $(this).data("load-url"),
                "edit",
                $(this).data("title-modal")
            );
        });

        //eventos que se desencadenan al inicializarse el formModal
        _this.$formModal.on("shown.bs.modal", function (evt) {
            //cuando hay mapa en el form modal
            if ($(_this.$mapDiv).length) {
                //console.log("hay mapa");
                let $modalBody = $(".modal-body", _this.$formModal);
                let originLat = 22.096667795582714;
                let originLon = -79.80700498766025;
                let zoom = 6;

                let $mapContainer = $modalBody.find(".map").first();
                console.log($mapContainer);

// Comprobamos si el mapa ya ha sido inicializado
                let mapAlreadyCreated = _this.$mainMap && _this.$mainMap._container === $mapContainer.get(0);

                console.log("Map already created:", mapAlreadyCreated);

                if (mapAlreadyCreated) {
                    console.log("ya estaba");
                    console.log(originLat);
                    console.log(originLon);
                    _this.$mainMap.setView([originLat, originLon], zoom);
                } else {
                    console.log("es nuevo");
                    console.log(originLat);
                    console.log(originLon);
                    // Inicializamos el mapa
                    _this.$mainMap = L.map($mapContainer.get(0)).setView([originLat, originLon], zoom);
                }
                //defino limites (cuba)
                // Definir los límites de la región (ejemplo: latitudes 40 a 45, longitudes -75 a -70)
                var southWest = L.latLng(19.5, -85), //limite mapa cuba abajo-izquierda
                    northEast = L.latLng(23.5, -73.9), //limite mapa de cuba arriba-derecha
                    bounds = L.latLngBounds(southWest, northEast);

                L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                    attribution: "© OpenStreetMap contributors",
                }).addTo(_this.$mainMap);

                // Restringir el mapa a la región definida
                _this.$mainMap.setMaxBounds(bounds);
                _this.$mainMap.on("drag", function () {
                    _this.$mainMap.panInsideBounds(bounds, {animate: false});
                });
                _this.$mainMap.on("dragend", function () {
                    _this.$mainMap.panInsideBounds(bounds, {animate: true});
                });

                //busco en el formulario, el campo coordinates y cojo su valor para marcar en el mapa
                let coordinatesValue = $(_this.$mapDiv)
                    .closest("#crud-form")
                    .find(".coordinates")
                    .val();
                //console.log(coordinatesValue);
                if (
                    coordinatesValue === null ||
                    coordinatesValue === "" ||
                    coordinatesValue === undefined
                ) {
                } else {
                    /*
                    en este segmento, voy a ajustar la visualidad del mapa,
                    en dependencia de que esta mostrando,
                    se definira el zoom y los limites
                    */
                    //1ro obtengo las coordenadas del punto
                    let arreglo = coordinatesValue.split(" ");
                    //console.log(arreglo);
                    let lat = arreglo[0];
                    let lon = arreglo[1];

                    //segundo, voy a definir zoom en dependencia de que es
                    //$mapProvince: '.map-province',
                    //$mapMunicipality: '.map-municipality',
                    //console.log($(_this.$mapDiv));
                    //if($(_this.$mapDiv).hasClass(_this.$mapProvince)){
                    if ($(_this.$mapProvince).length > 0) {
                        //console.log("es provincia");
                        zoom = 9;
                        //}else if($(_this.$mapDiv).hasClass(_this.$mapMunicipality)){
                    } else if ($(_this.$mapMunicipality).length > 0) {
                        //console.log("es municipio");
                        zoom = 12;
                    } else if ($(_this.$mapOriginDestination).length > 0) {
                        //console.log("es origen-destino");
                        zoom = 13;
                    } else if ($(_this.$mapLocation).length > 0) {
                        //console.log("es localidad");
                        zoom = 15;
                    }
                    //finalmente aplico
                    _this.$mainMap.setView([lat, lon], zoom);

                    /*map.eachLayer(function (layer) {
                      if (layer instanceof L.Marker) {
                        layer.remove();
                      }
                    });*/

                    //agrefo marcador en punto seleccionado
                    L.marker([lat, lon]).addTo(_this.$mainMap);
                }

                //para el caso del formulario de rutas
                //console.log($($(this).find('form')[0]).attr('name'));
                if ($($(this).find("form")[0]).attr("name") == "ct_route") {
                    //  console.log('mando a ejecutar funcion');
                    _this.routing(_this, false);
                }

                _this.$mainMap.on("click", function (e) {
                    if (!$(_this.$mapDiv).hasClass("no-clickable")) {
                        // Obtener las coordenadas del clic
                        var lat = e.latlng.lat;
                        var lon = e.latlng.lng;

                        // Hacer algo con las coordenadas (por ejemplo, mostrarlas en la consola)
                        console.log("Coordenadas del clic:", lat, lon);

                        //limpio mapa para volver a marcar
                        _this.$mainMap.eachLayer(function (layer) {
                            if (layer instanceof L.Marker) {
                                layer.remove();
                            }
                        });

                        //agrefo marcador en punto seleccionado
                        L.marker([lat, lon]).addTo(_this.$mainMap);

                        /*
                                L.circle([51.508, -0.11], {
                                    color: 'red',
                                    fillColor: '#f03',
                                    fillOpacity: 0.5,
                                    radius: 500
                                }).addTo(map)
                                */

                        //.bindPopup('A pretty CSS popup.<br> Easily customizable.')
                        //.openPopup();

                        //seteo el valor
                        $(_this.$mapDiv)
                            .closest("#crud-form")
                            .find(".coordinates")
                            .val(lat.toString() + " " + lon.toString());

                        /*console.log(
                          $(_this.$mapDiv).closest("#crud-form").find(".coordinates").val()
                        );*/
                    }
                });
            }
        });

        _this.$formModal.on("change", _this.$codeField, function (evt) {
            $(this).val($(this).val().toUpperCase());
        });

        _this.$formModal.on(
            "change",
            _this.$formMunicipalityProvinceSelect,
            function (evt) {
                //cambio en select provincia, debo limpiar mapa y centrar a esta provincia
                console.log("en select provincia");
                //limpio
                _this.$mainMap.eachLayer(function (layer) {
                    if (layer instanceof L.Marker) {
                        layer.remove();
                    }
                });

                let coordinatesProvince = [
                    $(_this.$formMunicipalityProvinceSelect)
                        .find(":selected")
                        .data("lat")
                        .toString(),
                    $(_this.$formMunicipalityProvinceSelect)
                        .find(":selected")
                        .data("lon")
                        .toString(),
                ];
                _this.$mainMap.setView([coordinatesProvince[0], coordinatesProvince[1]], 9);
            }
        );

        _this.$formModal.on(
            "change",
            _this.$formLocationOriginDestinationSelect,
            function (evt) {
                //cambio en select provincia, debo limpiar mapa y centrar a esta provincia
                console.log("en select origindestination");
                //limpio
                _this.$mainMap.eachLayer(function (layer) {
                    if (layer instanceof L.Marker) {
                        layer.remove();
                    }
                });

                let coordinatesOriginDestination = [
                    $(_this.$formLocationOriginDestinationSelect)
                        .find(":selected")
                        .data("lat")
                        .toString(),
                    $(_this.$formLocationOriginDestinationSelect)
                        .find(":selected")
                        .data("lon")
                        .toString(),
                ];
                _this.$mainMap.setView(
                    [coordinatesOriginDestination[0], coordinatesOriginDestination[1]],
                    13
                );

                //tema de seleccionar municipio al seleccionar localidad
                $(_this.$formMunicipalitySelect).val(
                    $(this).find(":selected").data("municipality")
                );
            }
        );

        _this.$formModal.on(
            "change",
            _this.$formMunicipalitySelect,
            function (evt) {
                //cambio en select provincia, debo limpiar mapa y centrar a esta provincia
                console.log("en select muncipio");
                //limpio

                _this.$mainMap.eachLayer(function (layer) {
                    if (layer instanceof L.Marker) {
                        layer.remove();
                    }
                });

                let coordinatesMunicipality = [
                    $(_this.$formMunicipalitySelect)
                        .find(":selected")
                        .data("lat")
                        .toString(),
                    $(_this.$formMunicipalitySelect)
                        .find(":selected")
                        .data("lon")
                        .toString(),
                ];
                _this.$mainMap.setView([coordinatesMunicipality[0], coordinatesMunicipality[1]], 13);
                //tema de seleccionar municipio al seleccionar localidad
                $(_this.$formLocationOriginDestinationSelect).val(null);
            }
        );

        _this.$formModal.on("change", _this.$originSelect, function (evt) {
            _this.routing(_this, true);
        });

        _this.$formModal.on("change", _this.$destinationSelect, function (evt) {
            _this.routing(_this, true);
        });

        //revisaaaaaaaaarrrrrrr
        _this.$formModal.on(
            "change",
            _this.$inputProductGasdfdsfdfsafenericPrice,
            function (evt) {
                //console.log(this);
                let val = parseFloat($(this).val());
                if (!isNaN(val)) {
                    $(this).val(val.toFixed(2));
                }
            }
        );

        _this.$formModal.on("change", _this.$selectUserRoles, function (evt) {
            if ($(this).val() == "" || $(this).val() == "ROLE_ADMIN") {
                $(_this.$selectUserAgencies)
                    .find("option:selected")
                    .each(function () {
                        console.log(this);
                        $(this).prop("selected", false);
                    });
                $(_this.$selectUserAgencies).trigger("change");
                $(_this.$selectUserAgencies).prop("disabled", true);
                $(_this.$selectUserAgencies).closest(".form-group").addClass("d-none");
            } else {
                $(_this.$selectUserAgencies).prop("disabled", false);
                $(_this.$selectUserAgencies)
                    .closest(".form-group")
                    .removeClass("d-none");
            }
        });

        _this.$formModal.on("change", _this.$radioOriginPersonData, function (evt) {
            var selectedValue = $(this).val();
            if (selectedValue == "exist") {
                $("#user_personsList").next(".select2-container").show();
                //habilito listado personas
                $("#user_personsList").prop("disabled", false); // Habilitar el elemento select
                $("#user_personsList").select2(); // Volver a inicializar select2
                //limpio y pongo readOnly los campos
                $(_this.$personInputs)
                    .find("input")
                    .each(function () {
                        $(this).val(null);
                        $(this).prop("readonly", true);
                        $(this).removeAttr("required");
                    });
                $(_this.$personInputs).prop("hidden", true);
            } else {
                //habilito los campos
                $(_this.$personInputs)
                    .find("input")
                    .each(function () {
                        $(this).val(null);
                        $(this).prop("readonly", false);
                        $(this).prop("required", true);
                    });
                $(_this.$personInputs).prop("hidden", false);
                //deshabilito lista
                // $("#user_personsList").prop('readonly', true);
                $("#user_personsList").prop("disabled", true); // Habilitar el elemento select
                $("#user_personsList").val(null).trigger("change"); // Establecer valor nulo y disparar evento de cambio
                $("#user_personsList").select2(); // Volver a inicializar select2
                $("#user_personsList").next(".select2-container").hide();
            }
        });

        _this.$formModal.on("click", _this.$collectionAddBtn, function (evt) {
            $(".collection-list")
                .find(".meetingPoint")
                .each(function (index) {
                    console.log("encontro");
                    $(this)
                        .find(".item-index")
                        .val(index + 1);
                    //.val("holamundo");
                });
        });

        _this.$formModal.on("click", _this.$btnNewUserPerson, function (evt) {
            _this.loadPersonFormModal($(this).data("load-url"));
            evt.preventDefault();
            evt.stopPropagation();
        });

        _this.$formModal.on(
            "change",
            "#ct_tariffication_requestType",
            function (evt) {
                //cambio en select provincia, debo limpiar mapa y centrar a esta provincia
                console.log("select tipo de solicitud");
                console.log($(this).find("option:selected").first().data('code'));
                //let vehiclePrototypeBlock =  $(".block-tariffication-vehiclePrototype")[0];
                //let routeBlock = $(".block-tariffication-route")[0];
                let vehiclePrototypeBlock = $(".block-tariffication-vehiclePrototype").first();
                let routeBlock = $(".block-tariffication-route").first();

                let locationAdditionalFeedTab = $(".tab-location-aditional-feed").first();
                let sheduleAdditionalFeedTab = $(".tab-shedule-aditional-feed").first();
                //console.log(vehiclePrototypeBlock);
                //console.log(routeBlock);
                if ($(this).find("option:selected").first().data('code') == "PRIV") {
                    //muestro bloque de prototipo, pongo requerido
                    vehiclePrototypeBlock.css("display", "block");
                    $("#ct_tariffication_vehiclePrototype").prop('required', true);

                    //oculto bloque de ruta, limpio valores y no es requerido
                    routeBlock.css("display", "none");
                    $("#ct_tariffication_route").val(null);
                    $("#ct_tariffication_route").removeAttr('required');
                    //tema de visibilidad de tabs
                    locationAdditionalFeedTab.css("display", "none");
                    sheduleAdditionalFeedTab.css("display", "flex");
                    //limpio collection oculta
                    _this.clearCollection($("#additionalFeeByLocation-list"));
                } else {
                    //muestro bloque de ruta y pongo requerido
                    routeBlock.css("display", "block");
                    $("#ct_tariffication_route").prop('required', true);
                    //oculto bloque de prototipo, limpio valores y no es requerido
                    vehiclePrototypeBlock.css("display", "none");
                    $("#ct_tariffication_vehiclePrototype").val(null);
                    $("#ct_tariffication_vehiclePrototype").removeAttr('required');
                    //tema de visibilidad de tabs
                    locationAdditionalFeedTab.css("display", "flex");
                    sheduleAdditionalFeedTab.css("display", "none");
                    //limpio collection oculta
                    _this.clearCollection($("#additionalFeeByShedule-list"));
                }
            }
        );

        _this.$personFormModal.on(
            "click",
            _this.$btnSavePersonModal,
            function (evt) {
                let $form = _this.$personFormModal.find("form");
                if ($form.valid()) {
                    //$form.trigger("submit");
                    $.LoadingOverlay("show");
                    $.ajax({
                        url: $($form).attr("action"),
                        type: $($form).attr("method"),
                        data: $($form).serialize(),
                    })
                        .done(function (res) {
                            console.log("guarde, ahora pido el que guarde");
                            console.log($($form).find("#person_dni").val());

                            //todo bien, hago peticion para obtener el nuevo
                            $.ajax({
                                url:
                                    "admin/get-person-by-email/" +
                                    $($form).find("#person_email").val(),
                                type: "GET",
                                /*data: {
                                  dni: $($form).find("#person_dni").val(),
                                },*/
                            })
                                .done(function (res) {
                                    //todo bien, hago peticion para obtener el nuevo
                                    let content = JSON.parse(res.content);
                                    console.log(content);
                                    let text =
                                        content.name +
                                        " " +
                                        content.surnames +
                                        // (content.secondsurnames != null
                                        //     ? " " + content.secondsurname
                                        //     : "")
                                        // +
                                        (content.nick != null
                                            ? " " + "(" + content.nick + ")"
                                            : "");
                                    var newOption = new Option(text, content.id, false, true);
                                    $(".service-request-owner")
                                        .append(newOption)
                                        .trigger("change");
                                    //corrijo el tema del spam
                                    $(".service-request-owner-name").val(
                                        content.name +
                                        " " +
                                        content.surnames
                                        // +
                                        // (content.secondsurname != null
                                        //     ? " " + content.secondsurname
                                        //     : "")
                                    );
                                    $(".person_dni").text(content.dni);
                                    $(".person_cellphone").text(content.phone);
                                    $(".person_email").text(content.email);
                                    _this.$personFormModal.modal("hide");
                                })
                                .fail(function (res) {
                                    alert("error pidiendo");
                                })
                                .always(function () {
                                    $.LoadingOverlay("hide");
                                });
                        })
                        .fail(function (res) {
                            Swal.fire({
                                title: "Oops!!",
                                text: JSON.parse(res.content)["msg"],
                                icon: "error",
                            });
                        })
                        .always(function () {
                            $.LoadingOverlay("hide");
                        });
                }
            }
        );

        _this.$formModal.delegate(
            ".meeting-collection-remove-item",
            "click",
            function (event) {
                let modal = $(this);
                let remButton = $(
                    event.target.closest(".meeting-collection-remove-item")
                );
                console.log(remButton);
                let remItem = $(remButton.closest(".meetingPoint"));
                console.log(remItem);
                //console.log(remButton.closest('.meetingPoint'));

                Swal.fire({
                    title: remButton.data("confirmation-msg-title"),
                    text: remButton.data("confirmation-msg-desc"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: remButton.data("confirmation-msg-btn-ok"),
                    cancelButtonText: remButton.data("confirmation-msg-btn-cancel"),
                }).then(function (confirmed) {
                    if (confirmed.value) {
                        _this.removeMeetingCollectionItem(remButton);
                    }
                });
            }
        );
        _this.$formModal.on("change", ".selectMeetingPoint", function (evt) {
            _this.routing(_this, true);
        });

        _this.$formModal.on("change", _this.$selectPersonUser, function (evt) {
            console.log("selecciona persona");
            //console.log($($(this).select2('data')[0].element).data('dni'));
            $(".person_name").text(
                $($(this).select2("data")[0].element).data("name") +
                " " +
                $($(this).select2("data")[0].element).data("surnames")
                // + " " +
                // $($(this).select2("data")[0].element).data("secondsurname")
            );
            // $("#user_person_nick").val($($(this).select2('data')[0].element).data('nick'));
            // $("#user_person_surname").val($($(this).select2('data')[0].element).data('surnames'));
            // $("#user_person_secondSurname").val($($(this).select2('data')[0].element).data('secondsurname'));

            $(".person_dni").text($($(this).select2("data")[0].element).data("dni"));
            $(".person_cellphone").text(
                $($(this).select2("data")[0].element).data("cellphone")
            );
            $(".person_email").text(
                $($(this).select2("data")[0].element).data("email")
            );
            //muestro un alert con los datos de la persona seleccionada
        });

        _this.$checkMultiselectionCrudTable.change(function (evt) {
            let multiselectCheck = this;
            //para que trabaje el del header o el bottom
            _this.$table.find(".crud-multiselection-check").each(function () {
                evt.preventDefault();
                $(this).prop("checked", $(multiselectCheck).prop("checked"));
            });
            //para los elementos de la tabla
            _this.$table.find(".record-selection-check").each(function () {
                $(this).prop("checked", $(multiselectCheck).prop("checked"));
            });
        });

        _this.$btnRapidEnable.click(function (evt) {
            let btn = $(this);
            let objectiveElements = [];
            _this.$table.find(".record-selection-check").each(function () {
                if ($(this).prop("checked")) {
                    objectiveElements.push($(this).val());
                }
            });
            if (objectiveElements.length == 0) {
                //no hay seleccion
                Swal.fire({
                    title: btn.data("msg-title-attention"),
                    text: btn.data("msg-no-elements-checked"),
                    icon: "error",
                });
            } else {
                //aca hago la pincha
                $.LoadingOverlay("show");
                $.ajax({
                    url: $(btn).data("action-url"),
                    type: "GET",
                    data: {
                        ids: objectiveElements, //JSON. stringify(objectiveElements)
                    },
                })
                    .done(function (res) {
                        //aca todo ok
                        toastr.success(
                            JSON.parse(res.content)["msg"],
                            JSON.parse(res.content)["title"],
                            {
                                timeOut: 3000,
                                //progressBar : true,
                                newestOnTop: true,
                            }
                        );
                        _this.$table.DataTable().ajax.reload();
                        //desmarco seleccion
                        _this.$table.find(".crud-multiselection-check").each(function () {
                            evt.preventDefault();
                            $(this).prop("checked", false);
                        });
                    })
                    .fail(function (res) {
                        Swal.fire({
                            title: "Oops!!",
                            text: JSON.parse(res.content)["msg"],
                            icon: "error",
                        });
                    })
                    .always(function () {
                        $.LoadingOverlay("hide");
                    });
            }
        });

        _this.$btnRapidDisable.click(function (evt) {
            let btn = $(this);
            let objectiveElements = [];
            _this.$table.find(".record-selection-check").each(function () {
                if ($(this).prop("checked")) {
                    objectiveElements.push($(this).val());
                }
            });
            if (objectiveElements.length == 0) {
                //no hay seleccion
                Swal.fire({
                    title: btn.data("msg-title-attention"),
                    text: btn.data("msg-no-elements-checked"),
                    icon: "error",
                });
            } else {
                //aca hago la pincha
                Swal.fire({
                    title: $(btn).data("confirmation-msg-title"),
                    text: $(btn).data("confirmation-msg-desc"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: $(btn).data("confirmation-msg-btn-ok"),
                    cancelButtonText: $(btn).data("confirmation-msg-btn-cancel"),
                }).then(function (confirmed) {
                    if (confirmed.value) {
                        $.LoadingOverlay("show");
                        $.ajax({
                            url: $(btn).data("action-url"),
                            type: "GET",
                            data: {
                                ids: objectiveElements, //JSON. stringify(objectiveElements)
                            },
                        })
                            .done(function (res) {
                                //aca todo ok
                                toastr.success(
                                    JSON.parse(res.content)["msg"],
                                    JSON.parse(res.content)["title"],
                                    {
                                        timeOut: 3000,
                                        //progressBar : true,
                                        newestOnTop: true,
                                    }
                                );
                                _this.$table.DataTable().ajax.reload();
                                //desmarco seleccion
                                _this.$table
                                    .find(".crud-multiselection-check")
                                    .each(function () {
                                        evt.preventDefault();
                                        $(this).prop("checked", false);
                                    });
                            })
                            .fail(function (res) {
                                Swal.fire({
                                    title: "Oops!!",
                                    text: JSON.parse(res.content)["msg"],
                                    icon: "error",
                                });
                            })
                            .always(function () {
                                $.LoadingOverlay("hide");
                            });
                    }
                });
            }
        });
    },

    clearCollection: function ($collection) {
        $collection.find(".collection-item").each(function (index, element) {
            $(element).addClass("d-none").remove();
        });
    },

    removeMeetingCollectionItem: function ($el) {
        let _this = this;
        $el.closest(".meetingPoint").addClass("d-none").remove();
        $(".collection-list")
            .find(".meetingPoint")
            .each(function (index) {
                $(this)
                    .find(".item-index")
                    .val(index + 1);
            });
        _this.routing(_this, true);
    },

    routing: function (_this, reset = false) {
        _this.$mainMap.eachLayer(function (layer) {
            if (layer instanceof L.Marker || layer instanceof L.Routing.Line) {
                layer.remove();
                //map.removeLayer(layer);
            }
        });
        //console.log("funcion routing");
        let selectOrigin = $(_this.$mapDiv)
            .closest("#crud-form")
            .find(".origin-field");
        let selectDestination = $(_this.$mapDiv)
            .closest("#crud-form")
            .find(".destination-field");
        let waypointsField = $(_this.$mapDiv)
            .closest("#crud-form")
            .find(".waypoints-field");
        if (selectOrigin.length > 0 && selectDestination.length > 0) {
            //console.log("primer if");
            if (selectOrigin.prop("selectedIndex")) {
                //console.log("segundo if");
                let coordinatesOrigin = [
                    selectOrigin.find(":selected").data("lat").toString(),
                    selectOrigin.find(":selected").data("lon").toString(),
                ];
                let coordinatesDestination = [
                    selectDestination.find(":selected").data("lat").toString(),
                    selectDestination.find(":selected").data("lon").toString(),
                ];
                let puntoInicio = L.latLng(coordinatesOrigin[0], coordinatesOrigin[1]);
                let puntoFin = L.latLng(
                    coordinatesDestination[0],
                    coordinatesDestination[1]
                );
                let waypointsArray = [];

                waypointsArray.push(puntoInicio);
                //los puntos de encuentro
                if (reset) {
                    console.log("resetea");
                    $(_this.$meetingPointList + " select").each(function (index, select) {
                        let indiceSeleccionado = select.selectedIndex;
                        let optionSeleccionado = $(select.options[indiceSeleccionado]);
                        let coordinatesMeetingPoint = [
                            optionSeleccionado.data("lat").toString(),
                            optionSeleccionado.data("lon").toString(),
                        ];
                        let puntoIntermedio = L.latLng(
                            coordinatesMeetingPoint[0],
                            coordinatesMeetingPoint[1]
                        );
                        waypointsArray.push(puntoIntermedio);
                    });
                } else {
                    //console.log("no resetea");
                    //console.log(waypointsField.val());
                    if (waypointsField.val() !== "") {
                        let tempArray = waypointsField.val().split(",");
                        tempArray.forEach(function (element, index) {
                            //console.log(index);
                            //if (index != 0 && index != tempArray.length - 1) {
                            let cord = element.split(" ");
                            waypointsArray.push(L.latLng(cord[0], cord[1]));
                            //console.log("add point");
                            //}
                        });
                    }
                }
                waypointsArray.push(puntoFin);

                // Crear un control de rutas
                var controlRutas = L.Routing.control({
                    waypoints: waypointsArray,
                    language: "es",
                    routeWhileDragging: true,
                }).addTo(_this.$mainMap);
                //console.log(map);
                //console.log(controlRutas);
                controlRutas.on("routesfound", function (event) {
                    var ruta = event.routes[0]; // Tomar la primera ruta encontrada
                    var distancia = ruta.summary.totalDistance; // Distancia en metros
                    var demora = ruta.summary.totalTime; // Demora en segundos

                    //console.log(ruta.waypoints);
                    let waypointsToSave = "";
                    ruta.waypoints.forEach(function (waypoint, index) {
                        //console.log("Waypoint", index + 1, ":", waypoint.latLng);
                        if (index != 0 && index != ruta.waypoints.length - 1) {
                            if (waypointsToSave == "") {
                                waypointsToSave =
                                    waypoint.latLng.lat + " " + waypoint.latLng.lng;
                            } else {
                                waypointsToSave =
                                    waypointsToSave +
                                    "," +
                                    waypoint.latLng.lat +
                                    " " +
                                    waypoint.latLng.lng;
                            }
                        }
                    });
                    waypointsField.val(waypointsToSave);
                    //console.log(waypointsToSave);

                    //estos seran los valores reales
                    $(".estimated-distance-field").val(distancia);
                    $(".estimated-time-field").val(demora);
                    //ahora lo que muestro
                    $(".estimatedDistance_view").val(
                        Math.round(distancia / 1000).toFixed(1) + " Km"
                    );
                    $(".estimatedTime_view").val(
                        _this.convertirSegundosAHorasYMinutos(demora)
                    );

                    //console.log("Distancia:", distancia, "metros");
                    //console.log("Demora:", demora, "segundos");
                });

                controlRutas.on("routeselected", function (e) {
                    //console.log("Se ha seleccionado una ruta");
                    //console.log("La ruta seleccionada es:", e.route);
                });
            }
        }
    },

    convertirSegundosAHorasYMinutos: function (segundos) {
        var horas = Math.floor(segundos / 3600);
        var minutos = Math.floor((segundos % 3600) / 60);

        // Formatear la salida para tener siempre dos dígitos para minutos
        if (minutos < 10) {
            minutos = "0" + minutos;
        }

        return horas + "h " + minutos + "min";
    },
};

(function () {
    let $scopeAdmin = $(".scope-admin");
    if ($scopeAdmin.length) {
        new AdminModule($scopeAdmin).init();
    }
})(jQuery, window, document);
